import { Inject, Injectable } from '@angular/core'
import {
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpParams,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'
import { APP_BASE_HREF } from '@angular/common'
import { Observable } from 'rxjs'

import { HttpFormParams } from './form-params'
import { Locale } from '@builder/common/lang/locale'
import { environment } from '@builder/environments/environment'

/**
 * Add RT MultiLingual headers to requests
 */
@Injectable()
export class RESTLanguageHeaders implements HttpInterceptor {
  constructor(private locale: Locale) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        'Rtml-Language': this.locale.code,
        'Rtml-Require-Selection': this.locale.code
      }
    })

    return next.handle(cloned)
  }
}

/**
 * Add Timezone offset headers to requests
 */
@Injectable()
export class TimeZoneOffsetHeaders implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        TZO: (new Date().getTimezoneOffset() / 60).toString()
      }
    })

    return next.handle(cloned)
  }
}

/**
 * Transforms a get request with an object as params into form encoded
 * i.e. { playlists: [{blog_id:1, post_id: 5}], per_page : 10}
 * becomes :
 *    playlists[0][blog_id]=1
 *    playlists[0][post_id]=5
 *    per_page=10
 */
@Injectable()
export class FormEncodingInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if not get, just move on
    if (req.method !== 'GET') {
      return next.handle(req)
    }

    /**
     * Handle params passed as object or string
     */
    if (req.params) {
      let httpParams: HttpParams

      if (typeof req.params === 'string') {
        httpParams = new HttpParams({ fromString: req.params })
      } else if (!(req.params instanceof HttpParams)) {
        httpParams = new HttpFormParams(req.params)
      }

      if (httpParams) {
        return next.handle(req.clone({ params: httpParams }))
      }
    }

    return next.handle(req)
  }
}

/**
 * AuthInterceptor handles requests as they go out, transforming them for some ease of use, and auth token stuff
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_BASE_HREF) private sitePath: string) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req.clone()

    // set the content type to application/json on posting methods
    if (
      (req.method === 'PUT' ||
        req.method === 'POST' ||
        req.method === 'DELETE') &&
      req.responseType === 'json'
    ) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      })
    }

    // direct requests to bff service
    request = request.clone({
      url: `${environment.BFF_HOST}${this.sitePath}${request.url}`
    })

    // add x-csrf header and withCredentials
    request = request.clone({
      headers: request.headers.set('x-csrf', '1'),
      withCredentials: true
    })

    /**
     * Handle params passed as object or string
     */
    if (req.method === 'GET' && req.params) {
      let httpParams: HttpParams

      if (typeof req.params === 'string') {
        httpParams = new HttpParams({ fromString: req.params })
      } else if (!(req.params instanceof HttpParams)) {
        httpParams = new HttpParams()

        Object.keys(req.params).forEach((key) => {
          httpParams = httpParams.append(key, req.params[key])
        })
      }

      if (httpParams) {
        request = request.clone({ params: httpParams })
      }
    }

    return next.handle(request)
  }
}

/**
 * Add X-Requested-By header
 */
@Injectable()
export class RequestedByInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      setHeaders: {
        'X-Requested-By': 'Builder;Builder-2.0'
      }
    })

    return next.handle(cloned)
  }
}

export let requestedByInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: RequestedByInterceptor,
  multi: true
}

export let authInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}

export let formEncodingInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: FormEncodingInterceptor,
  multi: true
}

export let languageCookieInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: RESTLanguageHeaders,
  multi: true
}

export let tzoInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TimeZoneOffsetHeaders,
  multi: true
}
