import { Component, Inject, InjectionToken, NgModule } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BuilderMaterialModule } from "./common/ng-material";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { environment } from '@builder/environments/environment'
import { CommonModule } from "@angular/common";
import { MyaButtonModule } from "./common/mya-button/mya-button.module";


interface InitialAppError {
  code: string;
  message: string;
  data: {status:string}
}

/**
 * This module/component is used to display an initial app error
 */
export const APP_ERROR = new InjectionToken<InitialAppError>('APP_ERROR')

@Component({
  selector: "signon-error",
  template: `
    <ng-container [ngSwitch]="error.data.status">
      <div *ngSwitchCase="406">
        <p i18n="switchInstanceModal.message|switchInstanceModalMessage@@switchInstanceModalMessage">We see you've joined MyAlpha before, but not for this region. Click “Cancel” to go to the site you’ve joined before or “Continue” to join this site. By joining this site, you will not lose access to the site you joined before.</p>
        <p class="stepper-buttons">
          <mya-button color="default" i18n="common.button|Cancel Button@@commonButtonCancel:Cancel" (click)="navigateAway()" [disabled]="submitting">Cancel</mya-button>
          <mya-button color="primary" i18n="common.button|Common Button - Continue@@commonbuttonContinue" (click)="joinSite()" [disabled]="submitting">Continue</mya-button>
        </p>
      </div>
      <div *ngSwitchDefault><p class="code-{{ error.code }}" [innerHTML]="error.message"></p></div>
    </ng-container>`,
  styles: [
    `
      :host {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
      }
      .stepper-buttons {
        display:flex;
        gap:20px;
      }
    `,
  ],
})
export class SignonErrorComponent {
  public error: InitialAppError;
  public submitting:boolean;

  constructor(
    public dialogRef: MatDialogRef<SignonErrorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { error: InitialAppError },
    private http:HttpClient
  ) {
    this.error = data.error;
  }
  joinSite() {
    const blogIdentifier: string = document.location.pathname.substring( 0, location.pathname.indexOf('/', 1) + 1 )
    this.submitting = true
    this.http.post( `${environment.BFF_HOST}${blogIdentifier}wp-json/wp/v2/user/site/join`, {}, { withCredentials: true, headers: {'X-CSRF': '1'}}).subscribe( result => {
      document.location = document.location.href;
    })
  }
  navigateAway() {
    this.submitting = true
    document.location = "https://alpha.org/run";
  }
}

@Component({
  selector: 'alpha-builder',
  template: ''
})
export class ErrorComponent{
  constructor(dialog:MatDialog, @Inject(APP_ERROR) error ) {
    dialog.open(SignonErrorComponent, {
      disableClose: true,
      maxWidth: 640,
      data: {error} 
    })
  }
}


@NgModule({
  declarations: [ErrorComponent, SignonErrorComponent],  
  bootstrap: [ErrorComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BuilderMaterialModule,
    MyaButtonModule,
    HttpClientModule
  ]
})
export class ErrorModule {}
