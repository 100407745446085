import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { map, Observable } from 'rxjs'

import { PlaylistService } from '@builder/training/playlists/playlist-service'
import { Playlist } from '@builder/training'

@Injectable({
  providedIn: 'root'
})
export class PlaylistDetailTitleResolver {
  constructor(private service: PlaylistService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): string | Observable<string> | Promise<string> {
    const id = route.paramMap.get('id')

    return this.service
      .getPlaylist(id, { _fields: 'title' })
      .pipe(map((playlist: Playlist) => playlist.title))
  }
}
