import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Attachment } from '@builder/common/models/attachment'
import { ProductTourService } from '@builder/product-tour/product-tour.service'
import { UserPreferences } from '@builder/users/preferences'

export interface TabData {
  title: string
  text: string
  image: Attachment
  video: { url: string }
}

export interface WhatsNewDialogData {
  title: string
  tabs: Array<TabData>
}

@Component({
  selector: 'whats-new-modal',
  templateUrl: 'whats-new-modal.component.html',
  styleUrls: ['whats-new-modal.component.less']
})
export class WhatsNewDialogComponent {
  public currentTabIndex = 0
  public maxWidth: string = '100%'
  public containerHeight: number

  constructor(
    public dialogRef: MatDialogRef<WhatsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WhatsNewDialogData,
    private tour: ProductTourService,
    public router: Router,
    public userPreferences: UserPreferences
  ) {}

  close(): void {
    this.dialogRef.close()
  }

  onConfirm() {
    this.close()
    this.router.navigate(['/preview-resources']).then((_) => {
      setTimeout(() => {
        this.tour.initializeOverview()
      }, 500)
    })
  }
}
