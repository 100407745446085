import { Injectable } from '@angular/core'

import { Organization } from '../organizations/organization'
import { DateUtils } from '../common/util/date'

import { UserPreferencesData } from './preferences'
import { ProfileStorageProvider } from './local-profile.provider'
import { BFFUserService } from './bff-user-service'

export interface ActiveAlpha {
  id: number
  title: string
  user_role: string
  date: Date
  end_date: Date
  parent?: number
  parent_blog_id?: number
}

export interface AlphaStats {
  nextEndDate: Date
  nextStartDate: Date
  recentEndDate: Date
  recentStartDate: Date
  numberActive: number
  numberUpcoming: number
  numberOpen: number
  numberAllTime: number
  numberYouth: number
  numberRegular: number
  currentProductName: string
  activeAlphas: Array<ActiveAlpha>
  upcomingAlphas: Array<{
    id: number
    title: string
    user_role: string
    date: Date
    end_date: Date
  }>
  openAlphas: Array<{
    id: number
    title: string
    user_role: string
    date: Date
    end_date: Date
  }>
  allProducts: Array<{ id: number; blog_id: number }>
  activeProducts: Array<{ id: number; blog_id: number }>
}

type AlphaStatus = 'upcoming' | 'open' | 'completed'
type AlphaActionOptions = 'create' | 'delete' | 'update'
interface AlphaAction {
  action: AlphaActionOptions
  alpha: any
}
export class User {
  public id
  public email: string
  public username: string
  public password: string
  public name: string
  public first_name: string
  public last_name: string
  public locality: string
  public country: string
  public role_at_church: string
  public role_at_alpha: string
  public user_roles: Array<string>
  public city: string
  public birthday: Date
  public registered_date: Date
  public organizationId: string
  public organization: Organization
  public noOrganization: boolean
  public mobileNumber: string
  public intlNumber: string
  public accountId: string
  public preferences: any
  public trainingStats
  public alphaStats: AlphaStats
  public mapCenter: { lat: number; lng: number; zoom: number }
  public preferredLanguage: string
  public isTeamMember: boolean
  public sitePath: string

  constructor(data = {}) {
    this.setData(data)
  }
  public setData(params: any): void {
    const obj = params
    const data = obj || {}

    this.organization =
      data.organization && data.organization instanceof Organization
        ? data.organization
        : new Organization(data.organization || {})

    if (data.birthday) {
      data.birthday = DateUtils.parse(data.birthday)
    }

    if (data.registered_date) {
      data.registered_date = DateUtils.parse(data.registered_date)
    }

    this.organizationId = null
    this.user_roles = []

    for (const prop in data) {
      if (prop !== 'organization') {
        this[prop] = data[prop]
      }
    }

    if (this.alphaStats) {
      this.alphaStats.upcomingAlphas.forEach((a) => {
        a.date = new Date(a.date)
        if (a.end_date) {
          a.end_date = new Date(a.end_date)
        }
      })
      this.alphaStats.activeAlphas.forEach((a) => {
        a.date = new Date(a.date)
        if (a.end_date) {
          a.end_date = new Date(a.end_date)
        }
      })
      this.alphaStats.openAlphas.forEach((a) => {
        a.date = new Date(a.date)
        if (a.end_date) {
          a.end_date = new Date(a.end_date)
        }
      })
      this.alphaStats.nextStartDate = this.alphaStats.nextStartDate
        ? new Date(this.alphaStats.nextStartDate)
        : null
      this.alphaStats.nextEndDate = this.alphaStats.nextEndDate
        ? new Date(this.alphaStats.nextEndDate)
        : null
      this.alphaStats.recentEndDate = this.alphaStats.recentEndDate
        ? new Date(this.alphaStats.recentEndDate)
        : null
      this.alphaStats.recentStartDate = this.alphaStats.recentStartDate
        ? new Date(this.alphaStats.recentStartDate)
        : null
    }
  }

  public user_can(role: string): boolean {
    return this.user_roles.indexOf(role) >= 0
  }
}

@Injectable()
export class CurrentUser extends User {
  constructor(private profileProvider: ProfileStorageProvider) {
    super(profileProvider.getProfile())

    if (this.alphaStats) {
      this.updateAlphaStats()
    }
  }

  public toJSON(): any {
    const json = { ...this }
    delete json.profileProvider
    return json
  }
  public loggedIn(): boolean {
    return !BFFUserService.isExpired()
  }

  public updatePreferences(prefs: UserPreferencesData): void {
    this.preferences = prefs
    this.storeLocalProfile()
  }

  public storeLocalProfile(): void {
    const userData = this.toJSON()
    this.profileProvider.update(userData)
  }

  public updateTrainingStats(stats): void {
    this.trainingStats = stats
    this.storeLocalProfile()
  }

  protected updateAlphaStats(): void {
    this.alphaStats.numberActive = this.alphaStats.activeAlphas.length
    this.alphaStats.numberUpcoming = this.alphaStats.upcomingAlphas.length
    this.alphaStats.numberOpen = this.alphaStats.openAlphas.length

    const dateSort = (a, b) => {
      const at = a.date ? a.date.getTime() : 0,
        bt = b.date ? b.date.getTime() : 0
      if (at === bt) {
        return 0
      }
      return at > bt ? 1 : -1
    }

    const now = new Date()

    this.alphaStats.activeAlphas = this.alphaStats.activeAlphas.sort(dateSort)
    this.alphaStats.upcomingAlphas =
      this.alphaStats.upcomingAlphas.sort(dateSort)
    this.alphaStats.openAlphas = this.alphaStats.openAlphas.sort(dateSort)

    const endDates = Array.from(
      new Set([
        ...this.alphaStats.upcomingAlphas,
        ...this.alphaStats.openAlphas,
        ...this.alphaStats.activeAlphas
      ])
    ).map((a) => a.end_date)

    this.alphaStats.nextStartDate = this.alphaStats.upcomingAlphas.length
      ? this.alphaStats.upcomingAlphas[0].date
      : null
    this.alphaStats.nextEndDate = endDates
      .sort()
      .filter((d) => d > now)
      .shift()

    this.alphaStats.recentStartDate = this.alphaStats.openAlphas.length
      ? this.alphaStats.openAlphas[0].date
      : null
    this.alphaStats.recentEndDate = endDates
      .sort()
      .filter((d) => d < now)
      .shift()
  }

  public userHasActiveAlphas(): boolean {
    return (
      this.alphaStats?.activeAlphas?.length +
        this.alphaStats?.upcomingAlphas?.length >
      0
    )
  }
}
