import {
  parseISO as parseDate,
  isValid as isValidDate,
  format as formatDate,
  isSameDay,
  isDate,
  differenceInCalendarWeeks,
  addDays,
  subDays,
  subWeeks,
  subMonths,
  addWeeks
} from 'date-fns'

import { isString } from '../util'

export const DAY_IN_MILLISECONDS = 86400000
export const WEEK_IN_MILLISECONDS = 604800000

export class DateUtils {
  public static addWeekToDate(date: Date, numWeeks: number = 1): Date {
    return addWeeks(date, 1)
  }

  public static addDayToDate(date: Date, days: number = 1): Date {
    return addDays(date, days)
  }

  public static subtractDaysFromDate(date: Date, days: number = 1): Date {
    return subDays(date, days)
  }

  public static subtractWeeksFromDate(date: Date, weeks: number = 1): Date {
    return subWeeks(date, weeks)
  }

  public static subtractMonthsFromDate(date: Date, months: number = 1): Date {
    return subMonths(date, months)
  }

  public static isSameDate(date1: Date, date2: Date): boolean {
    return isSameDay(date1, date2)
  }

  /**
   * Get a date object that is on the next day (0-7) from a date
   */
  public static getNextDayFromDate(day: number, date: Date): Date {
    // get the day to start from, 1 day ahead (in case today is the same day requested)
    const mDate = new Date(date.getTime() + DAY_IN_MILLISECONDS)

    while (mDate.getDay() !== day) {
      mDate.setTime(mDate.getTime() + DAY_IN_MILLISECONDS)
    }
    return mDate
  }

  public static getFollowingSunday(date): Date {
    return DateUtils.getNextDayFromDate(0, date)
  }

  public static getFollowingMonday(date): Date {
    return DateUtils.getNextDayFromDate(1, date)
  }

  public static getFollowingTuesday(date): Date {
    return DateUtils.getNextDayFromDate(2, date)
  }

  public static getFollowingWednesday(date): Date {
    return DateUtils.getNextDayFromDate(3, date)
  }

  public static getFollowingThursday(date): Date {
    return DateUtils.getNextDayFromDate(4, date)
  }

  public static getFollowingFriday(date): Date {
    return DateUtils.getNextDayFromDate(5, date)
  }

  public static getFollowingSaturday(date): Date {
    return DateUtils.getNextDayFromDate(6, date)
  }

  public static getNextWeekendStart(date): Date {
    return DateUtils.getFollowingFriday(date)
  }

  public static getWeeksBetweenDates(
    date1,
    date2,
    options?: { weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6; locale? }
  ): number {
    if (!options) {
      options = {
        weekStartsOn: 1 // we default to monday, sun could be used as a passed in option
      }
    }

    const weeksCal = differenceInCalendarWeeks(date1, date2, options)

    return weeksCal
  }

  public static parse(val: any): Date {
    if (isDate(val)) {
      return val
    }
    return parseDate(val)
  }

  public static parseTime(dateStr: string): Date {
    if (dateStr === '') {
      return null
    }

    const date = parseDate('2010-10-10T' + dateStr)
    if (!isValidDate(date)) {
      return null
    }
    return date
  }

  public static format(val: string | Date, format: string): string {
    if (isString(val)) {
      val = parseDate(val as string)
    }
    return formatDate(val as Date, format)
  }

  public static formatTime(date: Date, hourFormat = 12): string {
    let hours: any = date.getHours()
    let minutes: any = date.getMinutes()
    let ampm = 'AM'
    if (hours === 0) {
      hours = 12
    }
    if (hourFormat === 12 && hours >= 12) {
      ampm = 'PM'
      hours = hours > 12 ? hours - 12 : hours
    }
    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    return hours + ':' + minutes + (hourFormat === 12 ? ' ' + ampm : '')
  }

  public static isValid(val: any): boolean {
    if (val === null || val === undefined) {
      return false
    }
    if (isString(val)) {
      val = parseDate(val)
    }
    return isValidDate(val)
  }

  public static isDate(val: any): boolean {
    return isDate(val)
  }
}
