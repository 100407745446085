import { NgModule } from '@angular/core'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { AnnouncementComponent } from './announcement.component'

/**
 * Announcement Module
 *  - declare and export the callout Component
 *  - needs to be imported where you plan on using the <announcement> component
 */
@NgModule({
  imports: [BuilderIconModule],
  declarations: [AnnouncementComponent],
  exports: [AnnouncementComponent]
})
export class AnnouncementModule {}
