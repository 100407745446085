<mat-chip-set class="card-chips" *ngIf="playlist['category_title']">
  <mat-chip highlighted color="accent">{{
    playlist['category_title']
  }}</mat-chip>
</mat-chip-set>

<card
  [class.hoverable]="link"
  [image]="
    playlist.image?.sizes.card_short?.url ||
    playlist.image?.sizes.lesson_feature?.url ||
    '/images/static/ab-placeholder.png'
  "
  [image-placement]="imagePlacement"
  [routerLink]="[routerLinkBase, playlist.slug]"
>
  <a
    class="card-title link-title subtitle text-bold"
    [innerHTML]="playlist.title"
  ></a>

  <div class="card-footer">
    <playlist-meta-bar [playlist]="playlist"></playlist-meta-bar>
  </div>
</card>
