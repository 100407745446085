export class Term {
  public id: number
  public name: string
  public slug: string
  public parent: number
  public taxonomy: string
  public children: Array<Term>
  public glyph: string

  constructor(data: any = {}) {
    for (const prop in data) {
      this[prop] = data[prop]
    }
  }
}
