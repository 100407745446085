import { Component } from '@angular/core'

@Component({
  selector: 'page-loading',
  template: ``,
  styleUrls: ['page-loading.component.less']
})
export class PageLoadingComponent {
  constructor() {}
}
