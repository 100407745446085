import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpEvent
} from '@angular/common/http'

import { publishReplay, refCount } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { UserCache } from './cache.service'

/**
 * Interceptor
 */
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private _cache: any = {}

  constructor(private userCache: UserCache) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const noCachePaths = [
      'wp-json/wp/v2/user/',
      'wp-json/rtnetwork/v1/playlist',
      'wp-json/rtnetwork/v1/lesson'
    ]

    /*return next.handle( req )..do( ( event: HttpEvent<any> ) => {
            if ( event instanceof HttpResponse ) {
                // do stuff with response if you want
            }
        }, ( err: any ) => {
            if ( err instanceof HttpErrorResponse ) {
                if ( err.status === 401 ) {

                }
            }
        } );
        */

    if (req.headers.get('nocache')) {
      return next.handle(req)
    }

    /**
     * If it's anything but a post, there's going to be some clearing of caches
     * handle on an url basis
     */
    if (req.method !== 'GET') {
      /**
       * Posting or Deleting an alpha, clear the alpha cache
       */
      if (req.url.indexOf('wp-json/wp/v2/alpha') === 0) {
        // clear my alphas response
        this.clear('wp-json/wp/v2/alpha/mine')

        // clear id response
        this.clear(req.url)
      } else if (req.url === 'wp-json/jwt-auth/v1/token') {
        this.clear('', true)
      } else if (req.url === 'wp-json/wp/v2/user/logout') {
        /**
         * Intercept the logout call ( does not exist)
         * Purge our cache
         */
        this.clear('', true)

        return new Observable((observer) => {
          observer.next(
            new HttpResponse({
              body: { success: true },
              status: 200
            })
          )
          observer.complete()
        })
      } else if (req.url === 'wp-json/wp/v2/user/update') {
        /**
         * User is updated
         * Clear alpha caches
         */
        this.clear('wp-json/wp/v2/alpha')
      } else if (req.url.match(/\/survey\/user\/(\d+)\//)) {
        /**
         * User survey is updated
         * Clear survey caches
         */
        this.clear('wp-json/wp/v2/survey/user')
      }

      return next.handle(req)
    }

    for (let i = 0; i < noCachePaths.length; i++) {
      if (req.url.indexOf(noCachePaths[i]) === 0) {
        return next.handle(req)
      }
    }

    return this.getCached(req, next)
  }

  private getCached(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const paths = req.url.split('/')
    const obj = this._cache
    let curProp = obj
    // get rid of first one, its empty
    paths.shift()

    const params = req.params.toString()

    while (paths.length) {
      const part = paths.shift()
      if (!curProp[part]) {
        curProp[part] = {}
      }
      if (paths.length === 0) {
        if (!curProp[part][params]) {
          curProp[part][params] = next
            .handle(req)
            .pipe(publishReplay(1), refCount())
        }
        return curProp[part][params]
      }
      curProp = curProp[part]
    }

    return null
  }

  public clear(path: string = '', clearUserCache: boolean = false): void {
    if (clearUserCache) {
      this.userCache.clear()
    }

    if (path === '') {
      this._cache = {}
      return
    }

    // paths to clear
    const paths = path.split('/')
    const obj = this._cache
    let curProp = obj

    // empty first path
    paths.shift()

    while (paths.length) {
      const part = paths.shift()
      if (!curProp[part]) {
        return
      }

      if (paths.length === 0) {
        delete curProp[part]
        return
      }

      curProp = curProp[part]
    }
  }
}
