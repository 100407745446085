import {
  Component,
  HostBinding,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'
import { throttleTime } from 'rxjs/operators'
import { PageLoadingService } from '../page-loading/page-loading.service'
import { AppTheme } from '../util/themeSupports'

const COOKIE_STATUS_PROPERTY = 'cookies_agreed'

@Component({
  selector: 'cookie-consent-widget',
  templateUrl: 'cookie-consent.html',
  styleUrls: ['./cookie-consent.less']
})
export class CookieConsentComponent implements OnDestroy, AfterViewInit {
  @HostBinding('class.hidden') public get hidden(): boolean {
    // hidden if previously agreed
    return localStorage.getItem(COOKIE_STATUS_PROPERTY) ? true : false
  }
  @HostBinding('class.hidden') public get isPageLoading(): boolean {
    return this.isLoading
  }
  @Output() dismissOverlayEvent: EventEmitter<any> = new EventEmitter()
  public hasCookiePolicyLink: boolean

  private sizer: Subscription
  public isLoading: boolean = true

  constructor(
    private appTheme: AppTheme,
    private pageLoadingService: PageLoadingService
  ) {
    if (this.appTheme.link('terms-and-conditions')) {
      this.hasCookiePolicyLink = true
    }

    this.pageLoadingService.isLoading().subscribe((v) => {
      this.isLoading = v
    })
  }

  ngAfterViewInit(): void {
    this.sizer = fromEvent(window, 'resize').pipe(throttleTime(100)).subscribe()
  }

  ngOnDestroy(): void {
    this.cleanup()
  }

  private cleanup(): void {
    if (this.sizer) {
      this.sizer.unsubscribe()
    }
  }

  /**
   * Save user selection, and hide the widget
   */
  public onAgree(): void {
    this.dismissOverlayEvent.emit(false)
    this.cleanup()

    localStorage.setItem(COOKIE_STATUS_PROPERTY, '1')
  }
}
