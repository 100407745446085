import { Pipe, PipeTransform } from '@angular/core'
import { formatDate } from '@angular/common'

import { Locale } from '@builder/common/lang/locale.module'

/**
 * The abDate pipe will attempto use the Intl.DateTimeFormat to render the date
 * It's more accurate with the ordering of the components
 * If not available ( all browsers now seem to support it however ) fall back to Angular date pipe render *
 *
 */
@Pipe({
  name: 'abDate'
})
export class ABDatePipe implements PipeTransform {
  constructor(private _locale: Locale) {}

  transform(value: Date, format: string): string {
    try {
      const fmtObj = this.parse(format)

      const transformed: string = new Intl.DateTimeFormat(
        this._locale.dateLocale,
        fmtObj
      ).format(value)

      return transformed
    } catch {
      return formatDate(value, format, this._locale.dateLocale)
    }
  }

  /**
   * Parse the format, described here: https://angular.io/api/common/DatePipe
   * Into an Intl.DateTimeFormatOptions object
   */
  private parse(format: string): Intl.DateTimeFormatOptions {
    const fmtObj: any = {}

    if (format.indexOf('yyyy') >= 0) {
      fmtObj.year = 'numeric'
    } else if (format.indexOf('yy') >= 0) {
      fmtObj.year = '2-digit'
    }

    if (format.indexOf('MMMM') >= 0) {
      fmtObj.month = 'long'
    } else if (format.indexOf('MMM') >= 0) {
      fmtObj.month = 'short'
    } else if (format.indexOf('MM') >= 0) {
      fmtObj.month = '2-digit'
    }

    if (format.indexOf('dd') >= 0) {
      fmtObj.day = '2-digit'
    } else if (format.indexOf('d') >= 0) {
      fmtObj.day = 'numeric'
    }

    if (format.indexOf('EE') >= 0) {
      fmtObj.weekday = 'long'
    } else if (format.indexOf('E') >= 0) {
      fmtObj.weekday = 'short'
    }

    if (format.indexOf('hh') >= 0) {
      fmtObj.hour = '2-digit'
    } else if (format.indexOf('h') >= 0) {
      fmtObj.hour = 'numeric'
    }

    if (format.indexOf('mm') >= 0) {
      fmtObj.minute = '2-digit'
    } else if (format.indexOf('m') >= 0) {
      fmtObj.minute = 'numeric'
    }

    return fmtObj
  }
}
