import { environment } from '@builder/environments/environment'

/**
 * BFF User Service Class
 *  - Handles checking/fetching user state
 *  - Handles login/logout redirects
 */
export class BFFUserService {
  static CLAIMS: { type: string; value: string | number }[]

  /**
   * Check if is logged in and fetch/set user claims
   */
  public static async checkUserState(): Promise<boolean> {
    var req = new Request(environment.BFF_HOST + '/bff/user', {
      credentials: 'include',
      headers: new Headers({
        'X-CSRF': '1'
      })
    })

    try {
      var resp = await fetch(req)

      if (resp.ok) {
        let claims = await resp.json()
        BFFUserService.CLAIMS = claims
        if (claims) {
          return true
        } else {
          return false
        }
      } else if (resp.status === 401) {
        return false
      }
    } catch (e) {
      console.log('error checking user status')
    }
    return false
  }

  /**
   * Check for existence of profile in local storage or load it from API
   */
  public static async checkForProfile() {
    const blogIdentifier: string = document.location.pathname.substring(
      0,
      location.pathname.indexOf('/', 1) + 1
    )

    const profile = localStorage.getItem('profile')
    let apiEndpoint = 'callback'
    if (profile) {
      apiEndpoint = 'validate'
    }

    var req = new Request(
      `${environment.BFF_HOST}${blogIdentifier}wp-json/wp/v2/sso/token/${apiEndpoint}`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          user_email: BFFUserService.getClaim('email'),
          sitePath: blogIdentifier
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-CSRF': '1',
          TZO: (new Date().getTimezoneOffset() / 60).toString()
        })
      }
    )

    try {
      var resp = await fetch(req)
      if (resp.ok) {
        if (apiEndpoint === 'validate') {
          return true
        }
        let profile = await resp.json()
        localStorage.setItem('profile', JSON.stringify(profile))
        return true
      } else {
        let error = await resp.json()
        throw error
      }
    } catch (err) {
      throw err
    }
  }

  /**
   * Check if the session is expired
   */
  public static isExpired(): boolean {
    const expires: number = BFFUserService.getClaim(
      'bff:session_expires_in'
    ) as number
    return expires <= 0
  }

  /**
   * Get login URL
   */
  public static loginRedirectURL(): string {
    const path = window.location.pathname.replace(/\/$/, '')
    const returnUrl = encodeURIComponent(
      window.location.origin + path + window.location.search
    )
    return `${environment.BFF_HOST}/bff/login?returnUrl=${returnUrl}`
  }

  /**
   * Get logout URL
   */
  public static logoutRedirectURL(): string {
    const logoutPath = BFFUserService.getClaim('bff:logout_url')
    return `${environment.BFF_HOST}${logoutPath}`
  }

  /**
   * Redirect to login URL
   */
  public static loginRedirect(): void {
    window.location.href = BFFUserService.loginRedirectURL()
  }

  /**
   * Redirect to logout URL
   */
  public static logout() {
    this.clearBFCache()
    document.location.href = BFFUserService.logoutRedirectURL()
  }

  /**
   * Get a claim value for the user
   */
  public static getClaim(name: string) {
    let claim = BFFUserService.CLAIMS.find((claim) => claim.type === name)
    return claim.value ?? null
  }

  /**
   * Clear bfcache for different browsers
   */
  private static clearBFCache() {
    if (navigator.userAgent.includes('Safari')) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload()
        }
      }
    } else {
      window.addEventListener('unload', function () {})
      window.addEventListener('beforeunload', function () {})
    }
  }
}
