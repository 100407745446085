import {
  Component,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  OnChanges
} from '@angular/core'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'

import { Playlist } from '../playlist-class'
import { PlaylistService } from '../playlist-service'

@Component({
  selector: 'related-playlists',
  template: `<playlist-card
    [image-placement]="{ '': 'top', '(max-width:567px)': 'left' }"
    *ngFor="let playlist of playlists"
    [playlist]="playlist"
  ></playlist-card>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedPlaylistsComponent implements OnDestroy, OnInit, OnChanges {
  public playlists: Playlist[] = []

  @Input() playlist: Playlist

  @Input() limit: number

  private subscription: Subscription

  public get hasItems(): boolean {
    return this.playlists.length > 0
  }

  constructor(
    private service: PlaylistService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.limit = 3
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.playlist) {
      this.playlists = []

      if (c.playlist.currentValue) {
        const playlist: Playlist = c.playlist.currentValue

        this.subscription = this.service
          .list({
            training_tag: playlist.tags
              ? playlist.tags.map((t) => t.slug).join(',')
              : '',
            post__not_in: playlist.id + ':' + playlist.blog_id
          })
          .pipe(
            map((response) => {
              return this.limit
                ? response.body.slice(0, this.limit)
                : response.body
            })
          )
          .subscribe((results) => {
            this.playlists = results

            // this.cd.detectChanges();
            this.cd.markForCheck()
          })
      }
    }
  }
}
