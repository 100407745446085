import { Injectable } from '@angular/core'

import { AppTheme } from '@builder/common/util/themeSupports'
import { FBPixelSettings } from './fb-pixel-settings'

declare const fbq: any

/**
 * FBPixelService will attach the client JS library
 */
@Injectable()
export class FBPixelService {
  constructor(protected settings: FBPixelSettings) {
    this.initialize()
  }

  /**
   * Initialize tracker object with our settings
   */
  private initialize(): void {
    this.loadDeps()
  }

  /**
   * Create and attach script element for FBPixelService client js
   */
  private loadDeps(): void {
    ;(function (f, b, e, v, n, t, s) {
      if (f['fbq']) return
      n = f['fbq'] = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f['_fbq']) f['_fbq'] = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )
    fbq('init', this.settings.id)
    fbq('track', 'PageView')
  }
}

export const fbpixelSettingsProviderFactory = (appTheme: AppTheme) => {
  const obj = appTheme.value('tracking/fb-pixel')
  return new FBPixelSettings(obj)
}

export let fbpixelSettingsProvider = {
  provide: FBPixelSettings,
  useFactory: fbpixelSettingsProviderFactory,
  deps: [AppTheme]
}
