import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HttpFormParams } from '@builder/http'
import { map } from 'rxjs/operators'

import { ObStore, ObjectStore } from '@builder/common/mixins/object-store.mixin'
import { UserCache } from '@builder/common/cache/cache.service'
import { Locale } from '@builder/common/lang/locale'

import { Lesson } from '@builder/training/lessons/lesson-class'

@Injectable({
  providedIn: 'root'
})
export class LessonService extends ObStore(ObjectStore) {
  private _lessonEndpoint = 'wp-json/wp/v2/lesson'
  private _categoryEndpoint = 'wp-json/wp/v2/training_category'

  constructor(
    private http: HttpClient,
    cache: UserCache,
    private locale: Locale
  ) {
    super(cache, 'lesson', Lesson)
  }

  /**
   * Get Lessons
   */
  public list(query: any = {}) {
    return this.http
      .get<
        Array<Lesson>
      >(this._lessonEndpoint, { params: new HttpFormParams(query), observe: 'response' })
      .pipe(
        map((response) => {
          // map the raw items into lessons and cache them
          const lessons: Array<Lesson> = response.body.map((data) => {
            const lesson: Lesson = this.updateStore(data.id, data)
            return lesson
          })

          response = response.clone({
            body: lessons
          })

          return response
        })
      )
  }

  /**
   * Get an Lesson by Id or Slug
   */
  public getLesson(id: string, params: any = {}): any {
    return (
      this.getItemStore(id) ??
      this.getItem(
        id,
        params,
        (p) =>
          this.http.get<Lesson>(this._lessonEndpoint + '/' + id, { params: p }),
        'slug'
      )
    )
  }

  /**
   * Get lesson categories
   */
  getLessonCategories() {
    return this.http.get<Array<any>>(this._categoryEndpoint, {
      params: { rtml_language: this.locale.code }
    })
  }
}
