import {
  Component,
  HostListener,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core'
import { fromEvent, asyncScheduler } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

@Component({
  selector: 'back-to-top',
  templateUrl: 'back-to-top.component.html',
  styleUrls: ['back-to-top.component.less']
})
export class BackToTopComponent {
  private lastY: number
  private direction: number

  private timer

  constructor(private cd: ChangeDetectorRef) {
    this.lastY = window.scrollY

    const scroll = fromEvent(window, 'scroll').pipe(
      throttleTime(200, asyncScheduler, {
        trailing: true,
        leading: false
      })
    )
    scroll.subscribe((e) => this.onWindowScroll(e))
  }

  @HostBinding('class.visible') public get visible(): boolean {
    return this.direction === -1 && window.scrollY > 0
  }

  @HostListener('click', ['$event']) public onClick(event): void {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  public onTransitionEnd(e: TransitionEvent): void {
    this.timer = setTimeout(() => {
      this.direction = 1
    }, 2500)
  }

  private onWindowScroll(e: Event): void {
    clearTimeout(this.timer)
    const newY: number = window.scrollY
    this.direction = newY === 0 || newY > this.lastY ? 1 : -1
    this.lastY = newY

    this.cd.markForCheck()
  }
}
