import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'

/**
 * Please note, all these import statements must be in an exported funtion in order for ngast to pick up on them for our custom i18n builder
 */
export const loadDashboard = () =>
  import('./dashboard/dashboard-module').then((m) => m.DashboardModule)

export const loadSearch = () =>
  import('./search/search.module').then((m) => m.SearchModule)

export const loadUsers = () =>
  import('./users/users-module').then((m) => m.UsersModule)

export const loadTraining = () =>
  import('./training/training-module').then((m) => m.TrainingModule)

export const loadPages = () =>
  import('./pages/pages-module').then((m) => m.PagesModule)

export const loadAlphas = () =>
  import('./alphas/alpha-module').then((m) => m.AlphasModule)

export const loadSubtitles = () =>
  import('./subtitles/subtitles.module').then((m) => m.SubtitlesModule)

export const mainRouteTitles = () => ({
  recoverpassword: $localize`:alpha.title|RecoverPassword Page Title@@titleRecoverPasswordPage:Recover Password`,
  home: $localize`:common.words|Home@@commonWordHome:Home`,
  dashboard: $localize`:common.words|Dashboard@@commonWordDashboard:`,
  search: $localize`:common.words|Search Results@@commonWordSearchResults:Search Results`,
  user: $localize`:alpha.title|Users Page Title@@titleUsersPage:Users`,
  training: $localize`:training|Learning Centre Title@@learningCenterTitle:`,
  alphas: $localize`:alpha.mine|My Alphas@@alphaMyAlphas:`,
  'preview-resources': $localize`:common.resources|Preview Resources Label@@previewResourcesLabel:`,
  survey: $localize`:alpha.title|Post-Alpha Survey Title@@titlePostAlphaSurveyPage:Post-Alpha Survey`
})
export const updatedMainRouteTitles = () => ({
  recoverpassword: $localize`:alpha.title|RecoverPassword Page Title@@titleRecoverPasswordPage:Recover Password`,
  home: $localize`:common.words|Home@@commonWordHome:Home`,
  dashboard: $localize`:common.words|Dashboard@@commonWordDashboard:`,
  search: $localize`:common.words|Search@@commonWordSearch:Search`,
  user: $localize`:user.profile|My Account@@alphaAccount:Users`,
  training: $localize`:training|Learning Centre Title@@learningCenterTitle:`,
  alphas: $localize`:alpha.myAlpha|My Alphas Courses@@MyAlphasCourses:My Alpha Courses`,
  new: $localize`:create.course|Create an Alpha@@createAlpha:Create an Alpha`,
  'preview-resources': $localize`:common.resources|Preview Resources Label@@previewResourcesLabel:`,
  survey: $localize`:alpha.title|Post-Alpha Survey Title@@titlePostAlphaSurveyPage:Post-Alpha Survey`,
  createcourse: $localize`:create.course|My Alphas@@createAlpha:Create Alpha`
})
/**
 * Main App Routes
 */
export const routes: Routes = [
  {
    // redirect old recoverpassword links to /user/recover-password
    path: 'recoverpassword',
    redirectTo: 'user/recover-password',
    pathMatch: 'full'
  },
  {
    path: 'subtitles',
    loadChildren: loadSubtitles
  },
  {
    path: 'dashboard',
    loadChildren: loadDashboard
  },
  {
    path: 'search',
    loadChildren: loadSearch
  },
  {
    path: 'profile',
    loadChildren: loadUsers
  },
  {
    path: 'training',
    loadChildren: loadTraining
  },
  {
    path: 'alphas',
    loadChildren: loadAlphas
  },
  {
    path: '',
    loadChildren: loadPages
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    }),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutesModule {}
