import { Platform } from '@angular/cdk/platform'
import { Component, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BackLinkService } from '@builder/common'
import { CurrentUser } from '@builder/users'
import { PostAlphaSurvey } from '../post-alpha-survey'

@Component({
  selector: 'recommendations-callout-card',
  templateUrl: 'recommendations-callout.component.html',
  styleUrls: ['recommendations-callout.component.less']
})
export class RecommendationsCalloutsComponent {
  // friendly name for the user
  public friendlyName: string

  @Input() survey: PostAlphaSurvey

  isMobile: boolean

  constructor(
    user: CurrentUser,
    platform: Platform,
    route: Router,
    backLinkService: BackLinkService
  ) {
    this.friendlyName = user.first_name ? user.first_name : user.email
    this.isMobile = platform.IOS || platform.ANDROID ? true : false
    backLinkService.previousRoute.next(route['url'])
  }
}
