import { PROFILE_URL, BFF_HOST } from './vars'

/**
 * The process.env variables must be defined in the vars.js file so that our build task can also read them and ensure they exist so that compilation can succeed
 */

export const environment = {
  production: true,
  PROFILE_URL: PROFILE_URL,
  APP_INSIGHT_CON_STRING: null,
  BFF_HOST: BFF_HOST
}
