import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { ResourceCardComponent } from './resource-card.component'
import { CardModule } from '@builder/common/cards/card.module'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'

@NgModule({
  imports: [CommonModule, CardModule, BuilderIconModule, HttpClientModule],
  declarations: [ResourceCardComponent],
  exports: [ResourceCardComponent]
})
export class ResourceCardModule {}
