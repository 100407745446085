import { Injectable } from '@angular/core'

/**
 * Service which uses HTML geo location to get a users current position
 */
@Injectable()
export class UserLocationService {
  public enabled: boolean
  public position

  private loading
  private success_cbs = []
  private error_cbs = []

  private done() {
    this.success_cbs.forEach((callbackfn) => {
      callbackfn(this.position)
    })
    this.success_cbs.length = 0
  }

  private error(msg) {
    this.error_cbs.forEach((callbackfn) => {
      callbackfn(msg)
    })
    this.error_cbs.length = 0
  }

  public find(success, error): void {
    this.success_cbs.push(success)
    this.error_cbs.push(error)

    if (!navigator.geolocation) {
      this.enabled = false
      this.error('Location Services are not enabled.')
      return
    }

    if (!this.loading) {
      this.loading = true

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.enabled = true
          this.position = position
          this.done()
        },
        (e) => {
          this.enabled = false
          this.error(e.message)
        }
      )
    }

    if (this.position) {
      this.done()
      return
    } else if (this.enabled === false) {
      this.error('Location Services are not enabled.')
      return
    }
  }
}
