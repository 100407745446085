import { Component } from '@angular/core'

import { GoogleAnalyticsService, gaSettingsProvider } from './ga.service'

import { TrackingService } from '@builder/tracking/tracking-service'
import { TrackingEvent, PageView } from '@builder/tracking/tracking-events'

/**
 * This class handles subscribing to app events and triggering ga events
 */
@Component({
  selector: 'google-analytics',
  template: '',
  providers: [gaSettingsProvider, GoogleAnalyticsService]
})
export class GoogleAnalyticsComponent {
  constructor(
    private ga: GoogleAnalyticsService,
    private trackingService: TrackingService
  ) {
    this.subscribeToAppEvents()
  }

  /**
   * Subscribe to events dispatched by the alpha app
   */
  private subscribeToAppEvents(): void {
    // listen the tracking service for events
    this.trackingService.events.subscribe((event) =>
      this.onTrackingServiceEvent(event)
    )
  }

  /**
   * Handle GA Events coming from custom user interaction events dispatched through the TrackingService
   *
   * @param event
   */
  private onTrackingServiceEvent(event: TrackingEvent): void {
    if (event instanceof PageView) {
      this.ga.sendPageView(event.data.url, event.data.title)
    }
  }
}
