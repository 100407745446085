import { InjectionToken } from '@angular/core'
import { Subject } from 'rxjs'
import { Feature, FeaturesResponse } from './features'

// Angular Injection Token
export const FEATURES_MIDDLEWARE = new InjectionToken<FeaturesMiddleWare>(
  'FEATURES_MIDDLEWARE'
)

/**
 * A FeaturesMiddleWare must implement this interface in order to be used
 */
export interface FeaturesMiddleWare {
  setFeatures(obj: FeaturesResponse): void
  setFeature(key: string, feature: Feature): void
  getFeature(key: string): Feature
  getFeatures(): any
  isOn(key: string): boolean
  isOff(key: string): boolean

  whenChanged(key: string): Subject<Feature>

  updateUserAttributes(val: any): void
}
