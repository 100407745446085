import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fileExtension'
})
export class FileExtensionPipe implements PipeTransform {
  transform(path: string): string {
    return path.substr(path.lastIndexOf('.') + 1)
  }
}
