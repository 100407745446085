import { APP_BASE_HREF, PlatformLocation } from '@angular/common'
import { InjectionToken } from '@angular/core'

function getBaseHref(location: PlatformLocation): string {
  const blogIdentifier: string = location.pathname.substring(
    0,
    location.pathname.indexOf('/', 1) + 1
  )
  return blogIdentifier
}

export const baseHrefProvider = {
  provide: APP_BASE_HREF,
  deps: [PlatformLocation],
  useFactory: (location) => getBaseHref(location)
}

export const SITE_URL = new InjectionToken<string>('SITE_URL')

function getSiteUrl(baseHref: string): string {
  return document.location.origin + baseHref
}

/**
 * Localities Collection Provider object
 */
export const siteUrlProvider = {
  provide: SITE_URL,
  useFactory: (baseHref) => getSiteUrl(baseHref),
  deps: [APP_BASE_HREF]
}
