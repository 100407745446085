import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BuilderMaterialModule } from '@builder/common/ng-material'
import { AlphaLocaleModule } from '@builder/common/lang/locale.module'

import { ProgressCircleComponent } from './progress-circle.component'
import { ProgressCircleSimpleComponent } from './progress-circle-simple.component'

@NgModule({
  imports: [CommonModule, BuilderMaterialModule, AlphaLocaleModule],
  declarations: [ProgressCircleComponent, ProgressCircleSimpleComponent],
  exports: [ProgressCircleComponent, ProgressCircleSimpleComponent]
})
export class ProgressCircleModule {}
