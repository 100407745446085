import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core'

import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'clipboard-copy',
  templateUrl: 'clipboard-copy.component.html',
  styleUrls: ['clipboard-copy.component.less']
})
export class ClipboardCopyComponent implements AfterViewInit {
  @Input() text: string

  @ViewChild('input', { static: true }) inputRef: ElementRef

  protected copyTooltipText = $localize`:utility|Copy to Clipboard@@copyToClipboard:Copy to Clipboard`

  constructor(private snackbar: MatSnackBar) {}

  ngAfterViewInit() {
    this.inputElement.select()
  }

  public get inputElement(): HTMLInputElement {
    return this.inputRef.nativeElement
  }

  public onCopyInputFocus(event) {
    this.inputElement.select()
  }

  public copyToClipboard(): void {
    this.inputElement.select()
    document.execCommand('copy')

    this.snackbar.open(
      $localize`:utility|Copied to clipboard message@@copiedToClipboard:Copied to Clipboard`,
      '',
      { duration: 1500 }
    )
  }
}
