import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { from, switchMap, tap } from 'rxjs'
import { APP_ERROR, ErrorModule } from './app.error'

import { AppModule } from './app.module'
import { environment } from './environments/environment'
import { preloadTranslations } from './i18n-loader'
import { BFFUserService } from './users/bff-user-service'

if (environment.production) {
  enableProdMode()
}

/**
 * The only way to reliably load data before DI Services are instantiated is to make an XHR request before bootstrapping the application.
 * We do that here in order to preload any data that's critical to bootstrapping. ( Translations )
 */

from(BFFUserService.checkUserState())
  .pipe(
    tap((response) => {
      if (!response) {
        throw { redirect: BFFUserService.loginRedirectURL() }
      }
    }),
    switchMap((response) => BFFUserService.checkForProfile()),
    switchMap((response) => preloadTranslations())
  )
  .subscribe({
    next: (providers) => {
      platformBrowserDynamic(providers).bootstrapModule(AppModule)
    },
    error: (error) => {
      if (error.redirect) {
        document.location = error.redirect
        return;
      } else if (error.data?.switch_language) {
        document.location = document.location + '?lang=' + error.data.switch_language;
        return;
      } else if (!error.message) {
        error.message = 'An uspecified error occurred.'
      }

      // bootstrap an error component
      platformBrowserDynamic([{
        provide: APP_ERROR,
        useValue: error
      },]).bootstrapModule(ErrorModule)
    }
  })
