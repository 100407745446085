import { Injectable } from '@angular/core'
import { Router, Route } from '@angular/router'

import { AppTheme } from './util/themeSupports'

@Injectable()
export class Errors {
  public errors: Array<any> = []

  constructor(private router: Router) {}

  public clear(): void {
    this.errors.length = 0
  }

  public addError(error): void {
    if (!error.title) {
      error.title = $localize`:common.words|Common Words - Error@@commonWordsError:Error`
    }
    this.errors.push(error)
  }

  public navigateToError(error, observer = null): any {
    let errors: Array<AppError>
    if (error instanceof Array === false) {
      errors = [error as AppError]
    } else {
      errors = error as Array<AppError>
    }
    for (let i = 0; i < errors.length; i++) {
      this.addError(errors[i])
    }

    return this.navigate(observer)
  }

  public navigate(observer = null): any {
    const promise = this.router.navigate(['/'], {
      queryParams: { error: 1 },
      skipLocationChange: true
    })

    if (observer) {
      observer.next(promise)
      observer.complete()
    }
    return observer ? observer : promise
  }
}

/**
 * This Guard is used only when an 'errors' parameter is passed into the app
 * It utilizes the canLoad method ONLY ONCE, redirecting to the error page and then removing the canLoad check
 */
@Injectable()
export class ErrorGuard {
  constructor(
    private router: Router,
    private appTheme: AppTheme,
    private errors: Errors
  ) {}

  canLoad(route: Route): boolean {
    this.errors.navigateToError(this.appTheme.errors)
    this.router.config.forEach((item) => {
      if (!item.canLoad) return
      item.canLoad = item.canLoad.filter((func) => func !== ErrorGuard)
    })
    return false
  }
}

export interface AppError {
  title: string
  code: string
  message: string
  status: number
  link: {
    label: string
    url: string
  }
}
