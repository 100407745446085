import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { clone } from '@builder/common'
import { TrackingService } from '@builder/tracking/tracking-service'
import { TrackingEventName } from '@builder/tracking/tracking-events'
import { CurrentUser, User } from './user'

import { ProfileStorageProvider } from './local-profile.provider'
import { PageLoadingService } from '@builder/common/page-loading/page-loading.service'
import { UrlRegistrationService } from '@builder/common/url-registration/url-registration.service'
import { BFFUserService } from './bff-user-service'
import { CookieService } from '@builder/common/cookies/cookies.service'

@Injectable()
export class UserService {
  private _endpoint = 'wp-json/wp/v2/user'
  private verified: boolean

  constructor(
    private _http: HttpClient,
    private _currentUser: CurrentUser,
    private _router: Router,
    private profileProvider: ProfileStorageProvider,
    private trackingService: TrackingService,
    private loader: PageLoadingService,
    private urlRegistrationService: UrlRegistrationService,
    private cookies: CookieService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.verified && !BFFUserService.isExpired()) {
      return true
    }
    if (BFFUserService.isExpired()) {
      this.profileProvider.clear()
      BFFUserService.loginRedirect()
      return false
    }

    this.verified = true

    // after login, set the requested product, pass along url params to reg page
    const params = this.urlRegistrationService.getParams()

    if (params.product) {
      return this._router.navigate(['/alphas/new'], {
        state: { productContext: params }
      })
    }

    return true
  }

  /**
   *
   */
  public logout(): void {
    if (this.loader.show()) {
      this.loader.getLoader().style.paddingLeft = '0'
    }
    this.cookies.deleteAllWithName('dismissed_user_modal', '/')
    sessionStorage.clear()
    localStorage.clear()
    BFFUserService.logout()
  }

  /**
   * Get the profile data for a user
   */
  public getProfile(): Observable<any> {
    return this._http.get(this._endpoint + '/profile')
  }

  /**
   *
   */
  public saveProfile(data): Observable<any> {
    data.id = this._currentUser.id

    const orgChanging =
      // there was previously an organization but now there's not
      (!data.organization && this._currentUser.organization) ||
      // the organization is new or the id has changed
      !data.organization.id ||
      !this._currentUser.organization ||
      data.organization.id !== this._currentUser.organization.id
        ? clone(this._currentUser.organization)
        : null

    return this._http.post<CurrentUser>(this._endpoint + '/update', data).pipe(
      tap((response) => {
        this._currentUser.setData(response)

        this.profileProvider.update(response)

        // trigger UserSave event
        this.trackingService.trigger(TrackingEventName.UserSave, {
          user: this._currentUser
        })

        if (orgChanging) {
          this.trackingService.trigger(
            TrackingEventName.ChangeUserOrganization,
            {
              user: this._currentUser,
              previous: orgChanging,
              current: clone(this._currentUser.organization)
            }
          )
        }
      })
    )
  }

  public delete(user: User): Observable<any> {
    return this._http
      .delete(this._endpoint + '/' + user.id)
      .pipe(
        tap(() =>
          this.trackingService.trigger(TrackingEventName.DeletedUser, { user })
        )
      )
  }
}
