import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { FeaturesService } from '@builder/common/features/features.service'
import { FEATURE_CORA } from '@builder/common/features/feature-flag'

/**
 * Simple Course Create integration
 * Add script to page when feature is enabled
 */
@Injectable({
  providedIn: 'root'
})
export class Cora {
  constructor(
    features: FeaturesService,
    @Inject(DOCUMENT) private document: Document
  ) {
    features.whenChanged(FEATURE_CORA).subscribe((status) => {
      if (status.enabled) {
        this.document.body.classList.add('cora')
      } else {
        this.document.body.classList.remove('cora')
      }
    })
  }
}
