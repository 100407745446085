import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { BuilderMaterialModule } from '@builder/common/ng-material'

import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'

import {
  BuilderSelectComponent,
  BuilderSelectOptionDirective
} from './builder-select.component'

@NgModule({
  imports: [
    CommonModule,
    BuilderMaterialModule,
    BuilderIconModule,
    FormsModule
  ],
  declarations: [BuilderSelectOptionDirective, BuilderSelectComponent],
  exports: [BuilderSelectOptionDirective, BuilderSelectComponent]
})
export class BuilderSelectModule {}
