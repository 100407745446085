import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import { Observable, Subscriber } from 'rxjs'
import { AppInjector } from '../app.injector'

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class PathMatchService {
  protected pathSubscribers: Map<RegExp, Subscriber<any>> = new Map()

  /**
   * Return an Observable for a path match
   */
  public match(path: RegExp): Observable<string[]> {
    return new Observable((observer) => {
      this.pathSubscribers.set(path, observer)
    })
  }

  public unsubscribe(path: RegExp) {
    this.pathSubscribers.delete(path)
  }

  /**
   * Check the subscribers registered in the path listeners
   */
  public matches(req: HttpRequest<any>) {
    const url = req.url

    if (!this.pathSubscribers.size) {
      return
    }

    // check the registered paths for a match on this url
    this.pathSubscribers.forEach((subscriber, path) => {
      const matches = url.match(path)

      if (matches) {
        subscriber.next(matches)
      }
    })
  }
}

/**
 * The HTTP Interceptor
 * Injects the PathMatchService which it tests the intercepted urls against
 */
@Injectable()
export class PathMatchInterceptor implements HttpInterceptor {
  constructor(private service: PathMatchService) {}

  /**
   * Intercept method, tap the service with the request
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.service.matches(req)

    return next.handle(req)
  }
}

/**
 * @decorator RestPathMatch
 */
export function RestPathMatch(path: RegExp): MethodDecorator {
  return function (
    componentInstance: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    // get reference to the http interceptor
    const pathMatchService = AppInjector.get(PathMatchService)

    /**
     * ngOnInit for the component, register the path regexp on the path interceptor
     */
    const ngOnInit = componentInstance.constructor.prototype.ngOnInit
    componentInstance.constructor.prototype.ngOnInit = function (...args) {
      const compI = this

      pathMatchService.match(path).subscribe((matched) => {
        // call the class method decorated by @RestPathMatch with the matched items
        descriptor.value.apply(compI, matched.slice(1))
      })

      ngOnInit && ngOnInit.apply(this, args)
    }

    /**
     * ngOnDestroy, cleanup
     */
    const ngOnDestroy = componentInstance.constructor.prototype.ngOnDestroy
    componentInstance.constructor.prototype.ngOnDestroy = function (...args) {
      pathMatchService.unsubscribe(path)

      ngOnDestroy && ngOnDestroy.apply(this, args)
    }
    return descriptor
  }
}

/**
 * Export the PathMatchInterceptor for HTTP_INTERCEPTORS
 */
export const pathMatchInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: PathMatchInterceptor,
  multi: true
}
