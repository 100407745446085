import { Directive, HostListener, Input } from '@angular/core'

import { BackLinkService } from '@builder/common'
import { Lesson } from '@builder/training/lessons/lesson-class'
import { Playlist } from '@builder/training/playlists/playlist-class'

@Directive({
  selector: '[training-back-link]'
})
export class TrainingBackLinkDirective {
  @Input() playlist: Playlist
  @Input() lesson: Lesson

  @HostListener('click', ['$event']) onClick(event: Event): void {
    event.preventDefault()

    /**
     * Find contextual back location, or just go back to training
     */

    if (this.playlist && this.lesson) {
      // navigate back to playlist
      this.linkService.navigate(['/training', this.playlist.slug])
    } else if (this.lesson) {
      // if there's an item in our history just go back
      if (!this.linkService.navigateBack()) {
        // otherwise navigate back to training home
        this.linkService.navigate(['/training'])
      }
    }
    // if there's an item in our history just go back
    else if (!this.linkService.navigateBack()) {
      this.linkService.navigate(['/training'])
    }
  }

  public constructor(private linkService: BackLinkService) {}
}
