import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'mya-button',
  templateUrl: './mya-button.component.html',
  styleUrls: ['./mya-button.component.less']
})
export class MyaButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'navy' | 'default' = 'default'
  @Input() disabled: boolean = false
  @Input() size: 'small' | 'medium' | 'large' = 'medium'
  @Input() variant: 'contained' | 'outlined' | 'ghost' = 'contained'
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>()
  @Output() mouseDown: EventEmitter<void> = new EventEmitter<void>()

  constructor() {}
  onClick(): void {
    if (!this.disabled) {
      this.buttonClick.emit()
    }
  }

  onMouseDown(): void {
    this.mouseDown.emit()
  }
}
