import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

import {
  Attributes,
  FeatureDefinition,
  GrowthBook
} from '@growthbook/growthbook'

import { Locale } from '@builder/common/lang/locale'
import { Feature, FeaturesResponse } from '../features'
import { FeaturesMiddleWare, FEATURES_MIDDLEWARE } from '../features-middleware'
import { environment } from '@builder/environments/environment'

// add gb_debug=1 param to the URL to enable additional Growthbook debugging in the JS console
const DEBUG_URL_PARAM = 'gb_debug'

/**
 * FeaturesMiddleWare that utilizes the Growthbook JS SDK
 * Passed features loaded through myAlpha API
 */
@Injectable()
export class GrowthbookMiddleware implements FeaturesMiddleWare {
  // GB client
  private gb: GrowthBook

  // map of Subject by feature key
  private subjects: Map<
    string,
    Subject<{ enabled: boolean; feature: Feature }>
  > = new Map()

  // map of feature enabled states
  private featuresEnabled: Map<string, boolean> = new Map()

  /**
   * Constructor
   */
  constructor(locale: Locale) {
    // create our GrowthBook instance
    this.gb = new GrowthBook({
      enableDevMode: this.isDebug,
      attributes: {
        url: document.location.pathname,
        host: document.location.hostname,
        language: locale.code
      }
    })

    if (this.isDebug) {
      this.gb.debug = true
    }

    // listen to changes
    this.gb.setRenderer(() => this.handleFeaturesChange())
  }

  /**
   * Debug mode getter
   */
  public get isDebug(): boolean {
    const urlParams = new URLSearchParams(document.location.search)
    const val = urlParams.get(DEBUG_URL_PARAM)
    return val && parseInt(val) === 1
  }

  /**
   * Set the features on growthbook
   */
  public setFeatures(obj: FeaturesResponse): void {
    this.gb.setFeatures(this.parseFeatures(obj))
  }

  /**
   * Set a single feature on growthbook
   */
  public setFeature(key: string, feature: Feature): void {
    const features = this.gb.getFeatures()
    features[key] = this.parseFeature(feature)
    this.gb.setFeatures(features)
  }

  /**
   * Get a feature by it's key/name
   */
  public getFeature(key: string): Feature {
    const featureResult = this.gb.feature(key)
    if (!featureResult || featureResult.source === 'unknownFeature') {
      return null
    }
    const feature = {
      enabled: featureResult.on,
      key
    }

    return feature
  }

  /**
   * Get features
   */
  public getFeatures() {
    return this.gb.getFeatures()
  }

  /**
   * Is a feature on?
   */
  public isOn(key: string): boolean {
    const feature = this.getFeature(key)
    if (!feature) {
      if (!environment?.production) {
        console.warn(`The feature: "${key}" is not registered.`)
      }
      return false
    }
    return feature.enabled
  }

  /**
   * Is a feature off?
   */
  public isOff(key: string): boolean {
    const feature = this.getFeature(key)
    if (!feature) {
      if (!environment?.production) {
        console.warn(`The feature: "${key}" is not registered.`)
      }
      return true
    }
    return !feature.enabled
  }

  /**
   * Subscribe to when a feature changes state
   */
  public whenChanged(key: string): Subject<Feature> {
    const feature = this.getFeature(key)
    if (!feature) {
      if (!environment?.production) {
        console.warn(`The feature: "${key}" is not registered.`)
      }
      return new Subject()
    }
    return this.createConsumerSubject(key)
  }

  /**
   * Update user attributes
   */
  public updateUserAttributes(val: Attributes): void {
    this.gb.setAttributeOverrides(val)
  }

  /**
   * Get/Create a Subject identified by a key
   */
  private getSubject(key: string) {
    let subject = this.subjects.get(key)

    if (!subject) {
      subject = new Subject()
      this.subjects.set(key, subject)
    }
    return subject
  }

  /**
   * Listen to feature state changes and notify the consumer subject
   */
  private createConsumerSubject(key: string) {
    // get the feature we want to listen to
    const feature = this.getFeature(key)

    // create one for the consumer
    let consumerSubject = new BehaviorSubject<Feature>(feature)

    // if there's no feature return the consumer subject now
    if (!feature) {
      return consumerSubject
    }

    // set the feature state map
    this.featuresEnabled.set(feature.key, feature.enabled)

    // get our internal subject
    const internalSubject = this.getSubject(key)

    // subscribe to our subject for feature state change
    internalSubject.subscribe((result) => {
      consumerSubject.next(result.feature)
    })

    // return to consumer
    return consumerSubject
  }

  /**
   * When features are changed, check our on/off listeners to respond
   */
  private handleFeaturesChange(): void {
    // go through the subjects, dispatching next
    this.subjects.forEach((subject, key) => {
      // get the feature
      const feature = this.getFeature(key)

      // it's current state
      const featureEnabled = this.featuresEnabled.get(feature.key)

      // has the state changed
      const hasChanged = featureEnabled !== feature.enabled

      // set the new state
      this.featuresEnabled.set(feature.key, feature.enabled)

      // if it's changed, notify subscribers
      if (hasChanged) {
        subject.next({ enabled: feature.enabled, feature })
      }
    })
  }

  /**
   * Parse the features being provided into those for consumption by Growthbook
   */
  private parseFeatures(
    obj: FeaturesResponse
  ): Record<string, FeatureDefinition> {
    const features: Record<string, FeatureDefinition> = {}
    Object.values(obj).forEach((feature) => {
      features[feature.key] = this.parseFeature(feature)
    })
    return features
  }

  /**
   * Parse a feature into growthbook format
   */
  private parseFeature(feature: Feature): FeatureDefinition {
    // set the feature state map
    this.featuresEnabled.set(feature.key, feature.enabled)

    return {
      defaultValue: feature.data ? feature.data : feature.enabled,
      rules: feature.rules
    }
  }
}

/**
 * Localities Collection Provider object
 */
export const growthbookProvider = {
  provide: FEATURES_MIDDLEWARE,
  useClass: GrowthbookMiddleware
}
