import {
  Component,
  Input,
  ViewEncapsulation,
  QueryList,
  ContentChildren,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef
} from '@angular/core'
import { MatChip } from '@angular/material/chips'

@Component({
  selector: 'card',
  templateUrl: 'card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CardComponent {
  @Input() image: string
  @Output() imageClick: EventEmitter<any> = new EventEmitter()
  @ContentChildren(MatChip) chips: QueryList<MatChip>

  @ContentChild('defaultCardImage', { static: true })
  defaultCardImage: TemplateRef<any>
}
