export class VideoData {
  public ID: number
  public blog_id: number
  public duration: number
  public width: number
  public height: number
  public meta: any
  public provider: string
  public language: any
  public subtitles: Array<{ language: string; video: VideoData }> = []
  public languages: Array<VideoData>
  public url: string
  public external_url: string

  private options: any

  public reloading: boolean

  constructor(data = {}, options = {}) {
    this.options = Object.assign({ sourceName: 'Original' }, options)

    Object.assign(this, data)

    if (!this.meta) {
      this.meta = {}
    }

    this.prepareSubtitles()
    this.prepareDownloads()
    this.prepareLanguages()
  }

  public setDownloads(downloads: Array<any>): void {
    this.meta.download = downloads
    this.prepareDownloads()
  }

  private prepareLanguages(): void {
    if (!this.languages) {
      return
    }
    const mapLanguage = (collection) => {
      return collection.map((item) => {
        return new VideoData(item)
      })
    }
    this.languages = mapLanguage(this.languages)
  }

  private prepareSubtitles(): void {
    if (!this.subtitles) {
      return
    }
    const mapSubtitle = (collection) => {
      return collection.map((item) => {
        item.video = new VideoData(item.video)
        return item
      })
    }
    this.subtitles = mapSubtitle(this.subtitles)
  }

  private prepareDownloads(): void {
    if (!this.meta || !this.meta.download) {
      return
    }
    const mapDownload = (collection) => {
      return collection
        .map((item) => {
          if ('source' === item.quality) {
            item.name = this.options.sourceName
          } else {
            item.name =
              item.quality.toUpperCase() +
              ('hd' === item.quality ? ' ' + item.height + 'p' : '')
          }
          return item
        })
        .sort((a, b) => a.size - b.size)
    }
    this.meta.download = mapDownload(this.meta.download)
  }
}
