import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'

import { SearchService } from '../search.service'
import { ISearchTerm } from './popular-search-terms.component'

@Injectable({
  providedIn: 'root'
})
export class PopularSearchTermsResolver {
  constructor(private searchService: SearchService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Array<ISearchTerm>> {
    return this.searchService.getTerms()
  }
}
