import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { Observable } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

import { CurrentUser } from '@builder/users/user'

import { PlaylistService } from '@builder/training/playlists/playlist-service'
import { Playlist } from '@builder/training/playlists'
import { Errors } from '@builder/common'

/**
 * Playlist Resolver to load playlist before rendering page
 */
@Injectable()
export class PlaylistDetailResolver {
  constructor(
    private playlistService: PlaylistService,
    protected errors: Errors,
    private currentUser: CurrentUser
  ) {}

  /**
   * Resolve /training/playlist/:id
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<Playlist> | any {
    // playlist id
    const id = route.params['id']

    return this.playlistService
      .getPlaylist(id, {
        _fields: 'id,blog_id,title,content,lessons,tags,duration,slug,image'
      })
      .pipe(
        map((playlist: Playlist) => {
          // reset current lesson
          playlist.setCurrentLesson(null)

          // check perms
          if (!this.canViewPlaylist(playlist)) {
            playlist.canView = false
          }

          return playlist
        }),

        catchError((error) => {
          return this.errors.navigateToError(error.error || error)
        })
      )
  }

  /**
   * Check if the playlist is private and user is logged in or not
   */
  public canViewPlaylist(playlist: Playlist = null): boolean {
    const isLoggedIn: boolean = this.currentUser.loggedIn()

    if (playlist.status === 'private' && !isLoggedIn) {
      return false
    }
    return true
  }
}
