import { Injectable } from '@angular/core'
import { RouterStateSnapshot, TitleStrategy } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { Observable, Subject } from 'rxjs'

import { AppTheme } from '../util/themeSupports'
import { updatedMainRouteTitles, mainRouteTitles } from '@builder/app.routes'
import { TitleChange, TrackingEvent } from '@builder/tracking'

@Injectable({
  providedIn: 'root'
})
export class CustomTitleStrategyService extends TitleStrategy {
  private useUpdatedMainRouteTitles: boolean = false
  public mainRouteTitle = ''
  public parentRoute = ''

  setUseComponentTitle(value: boolean) {
    this.useUpdatedMainRouteTitles = value
  }
  get events(): Observable<TrackingEvent> {
    return this._events
  }
  private _events: Subject<TrackingEvent> = new Subject()

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const previousTitle = this.title.getTitle()
    const siteTitle = this.appTheme.value('site_name')
    let currentRouteTitle = this.buildTitle(snapshot)
    if (currentRouteTitle == 'survey') {
      const mainRouteTitle = mainRouteTitles()['survey']?.concat(' - ') ?? ''
      this.title.setTitle(`${mainRouteTitle}${siteTitle} `)
    } else {
      currentRouteTitle = currentRouteTitle
        ? currentRouteTitle.concat(' - ')
        : ''
      this.parentRoute = snapshot.url.split('/').at(1).split('?').at(0)
      if (this.useUpdatedMainRouteTitles) {
        if (this.parentRoute == 'alphas' && snapshot.url.split('/').at(2)) {
          this.parentRoute = snapshot.url.split('/').at(2).split('?').at(0)
        }
        this.mainRouteTitle =
          updatedMainRouteTitles()[this.parentRoute]?.concat(' - ') ?? ''
      } else {
        this.mainRouteTitle =
          mainRouteTitles()[this.parentRoute]?.concat(' - ') ?? ''
      }
      this.title.setTitle(
        `${currentRouteTitle}${this.mainRouteTitle}${siteTitle} `
      )
    }

    // trigger a title change event
    this._events.next(
      new TitleChange({
        title: this.title.getTitle(),
        previous: { title: previousTitle }
      })
    )
  }

  public getTitle(): string {
    return this.title.getTitle()
  }

  constructor(
    private title: Title,
    private appTheme: AppTheme
  ) {
    super()
  }
}
