import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AlphaLocaleModule } from '../lang/locale.module'
import { AppLinkModule } from '../directives/app-link'
import { BuilderIconModule } from '../icons/ab-icon.module'

import { CookieConsentComponent } from './cookie-consent'

@NgModule({
  imports: [CommonModule, AlphaLocaleModule, AppLinkModule, BuilderIconModule],
  declarations: [CookieConsentComponent],
  exports: [CookieConsentComponent]
})
export class CookieConsentModule {}
