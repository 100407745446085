import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LookupAlphaComponent } from './lookup-alpha.component'
import { BuilderSelectModule } from '@builder/common/forms/builder-select/builder-select.module'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { RouterModule } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { BuilderMaterialModule } from '@builder/common/ng-material'

@NgModule({
  declarations: [LookupAlphaComponent],
  imports: [
    CommonModule,
    BuilderIconModule,
    BuilderSelectModule,
    BuilderMaterialModule,
    RouterModule,
    MatButtonModule
  ],
  exports: [LookupAlphaComponent]
})
export class LookupAlphaModule {}
