import { NgModule } from '@angular/core'

import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { AlphaLocaleModule } from '@builder/common/lang/locale.module'

import { BackToTopComponent } from './back-to-top.component'

@NgModule({
  imports: [BuilderIconModule, AlphaLocaleModule],
  declarations: [BackToTopComponent],
  exports: [BackToTopComponent]
})
export class BackToTopModule {}
