import { Injectable } from '@angular/core'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Observable, Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import {
  TrackingEvent,
  EVENTS_MAP,
  TrackingEventName,
  PageView,
  TitleChange
} from './tracking-events'
import { AppInjector } from '@builder/app.injector'
import { CustomTitleStrategyService } from '@builder/common/custom-title/custom-title-strategy.service'

/**
 * The main service for listening to and triggering TrackingEvents
 */
@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  /** An observable of router events */
  get events(): Observable<TrackingEvent> {
    return this._events
  }
  private _events: Subject<TrackingEvent>

  public triggerEvent(e) {
    this._events.next(e)
  }

  /**
   *
   */
  public trigger(eventName: TrackingEventName, data?) {
    const eventClass = EVENTS_MAP.get(eventName)

    if (eventClass) {
      this._events.next(new eventClass(data))
    } else {
      console.warn(`There is no event mapped to ${eventName}`)
    }
  }

  constructor(router: Router, titleStrategy: CustomTitleStrategyService) {
    this._events = new Subject()

    let initialUrl: string
    let initialPath: string

    let navigationChangingPageView: PageView = null

    /**
     * Router Events
     */
    router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationStart) {
            initialUrl = document.location.href
            initialPath = document.location.pathname
          }
          if (event instanceof NavigationEnd) {
            navigationChangingPageView = new PageView({
              url: event.url,
              previous: { url: initialUrl, path: initialPath }
            })
          }
          return null
        }),
        filter((e) => e !== null)
      )
      .subscribe((event) => this.triggerEvent(event))

    // listen to the title change from the title strategy service
    titleStrategy.events
      .pipe(filter((event) => event instanceof TitleChange))
      .subscribe((event) => {
        // add title to pageview event
        navigationChangingPageView.data.title = event.data.title
        navigationChangingPageView.data.previous.title =
          event.data.previous.title

        // trigger event
        this.triggerEvent(navigationChangingPageView)

        // clear local ref
        navigationChangingPageView = null
      })
  }
}

/**
 * @decorator TrackEvent
 *
 * @param path string
 */
export function TrackEvent(
  eventName: TrackingEventName,
  mapCB
): MethodDecorator {
  return function (
    componentInstance: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    // get reference to the http interceptor
    const trackingService = AppInjector.get(TrackingService)

    const nextValue = descriptor.value
    descriptor.value = function (...args) {
      // map the data from the callback passed through in in the @TrackEvent decorator
      const data = mapCB.apply(this, args)

      // trigger the event
      trackingService.trigger(eventName, data)

      // pass along the original function call
      nextValue && nextValue.apply(this, args)
    }

    return descriptor
  }
}
