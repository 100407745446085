import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AppTheme } from '@builder/common'
import { CurrentUser } from './users'
import { AnnouncementsService } from './announcements/announcements.service'
import { AppInjector } from './app.injector'

/**
 * Import Features
 */
import { Survicate } from './features/survicate'
import { Tidio } from './features/tidio'
import { ProductTour } from './features/driverjs'
import { SimpleCourseCreate } from './features/simple-course-create'
import { FeaturesService } from './common/features/features.service'
import { CustomTitleStrategyService } from './common/custom-title/custom-title-strategy.service'
import { PageLoadingService } from './common/page-loading/page-loading.service'
import { Observable, takeUntil } from 'rxjs'
import { WithDestroy } from './common/mixins/with-destroy'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { BuilderMenuComponent } from './common/menu/builder-menu.component'
import { PageView, TrackingEventName, TrackingService } from './tracking'
import { SideMenuComponent } from './common/side-menu/side-menu.component'
import { ProfileUrlsService } from './common/profile-urls/profile-urls.service'
import {
  FEATURE_CORA,
  FEATURE_PRODUCT_TOUR,
  FEATURE_SIMPLE_COURSE_CREATE,
  FEATURE_USER_PROFILE
} from './common/features/feature-flag'
import { Cora } from './features/cora'

@Component({
  selector: 'alpha-builder',
  template: `
    <div id="bodyWrapper">
      <div
        class="page-header"
        *ngIf="currentUser.loggedIn() && !isFullScreenPage"
      >
        <announcement
          id="announcement"
          *ngIf="announcementService.latestAnnouncement && !showCookieConsent"
          [announcement]="announcementService.latestAnnouncement"
        ></announcement>
        <mobile-header
          *ngIf="!userProfileIsEnabled && isMobileView"
          data-qa-name="manageAlphas-container-mobileHeader"
        ></mobile-header>
        <user-header
          *ngIf="userProfileIsEnabled"
          data-qa-name="manageAlphas-container-userHeader"
        ></user-header>
      </div>
      <div class="page-content">
        <ng-container
          *ngIf="currentUser.loggedIn() && !isMobileView && !isFullScreenPage"
        >
          <builder-menu
            *ngIf="!userProfileIsEnabled"
            id="builderMenu"
            (toggleSearchPanelEvent)="toggleSearchPanel()"
          ></builder-menu>
          <side-menu
            *ngIf="userProfileIsEnabled"
            id="sideMenu"
            (toggleSearchPanelEvent)="toggleSearchPanel()"
          ></side-menu>
        </ng-container>
        <div page-loading-target id="contentContainer">
          <search-panel
            *ngIf="!searchUpdateEnabled && !isFullScreenPage"
            [showSearch]="showSearchPanel"
            (toggleSearchPanelEvent)="toggleSearchPanel()"
          ></search-panel>
          <page-loading></page-loading>
          <router-outlet></router-outlet>
          <back-to-top></back-to-top>
        </div>
      </div>
      <div class="page-footer" *ngIf="!isFullScreenPage">
        <old-mobile-menu
          id="old-mobile-menu"
          *ngIf="
            currentUser.loggedIn() && isMobileView && !userProfileIsEnabled
          "
          (toggleSearchPanelEvent)="toggleSearchPanel()"
        ></old-mobile-menu>
        <mobile-menu
          id="mobile-menu"
          *ngIf="currentUser.loggedIn() && isMobileView && userProfileIsEnabled"
          (toggleSearchPanelEvent)="toggleSearchPanel()"
        ></mobile-menu>
      </div>
      <div *ngIf="enabledCookieConsent && showCookieConsent" class="overlay">
        <cookie-consent-widget
          (dismissOverlayEvent)="dismissCookieOverlay()"
        ></cookie-consent-widget>
      </div>
    </div>

    <router-outlet name="signon"></router-outlet>

    <google-analytics *ngIf="supportsGA"></google-analytics>

    <geotargetly *ngIf="supportsGeotargetly"></geotargetly>

    <fb-pixel *ngIf="supportsFBPixel"></fb-pixel>

    <heap-analytics *ngIf="supportsHeap"></heap-analytics>

    <intercom *ngIf="supportsIntercom"></intercom>

    <tidio *ngIf="supportsTidio"></tidio>

    <matomo *ngIf="supportsMatomo"></matomo>
  `,
  styles: [
    `
      /* Styles for the overlay */
      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
        z-index: 1000000002; /* Ensure it's above other content */
      }
    `
  ]
})
export class AlphaBuilderComponent extends WithDestroy() {
  @ViewChild(BuilderMenuComponent, { static: false })
  builderMenu: BuilderMenuComponent
  @ViewChild(SideMenuComponent, { static: false }) sideMenu: SideMenuComponent

  public supportsGA: boolean
  public supportsMatomo: boolean
  public supportsGeotargetly: boolean
  public supportsFBPixel: boolean
  public supportsHeap: boolean
  public supportsIntercom: boolean
  public supportsTidio: boolean
  public enabledCookieConsent: boolean
  public enabledSimpleCreateCourse: boolean
  public showCookieConsent: boolean = false
  public userProfileIsEnabled: boolean = false
  public simpleCourseCreateEnabled: boolean = false
  public coraEnabled: boolean = false
  public searchUpdateEnabled: boolean = false
  public isMobileView: boolean
  public showSearchPanel: boolean = false
  public isFullScreenPage: boolean = false
  public productTourOverviewEnabled: boolean = false

  constructor(
    // app theme
    appTheme: AppTheme,
    // announcement service,
    public announcementService: AnnouncementsService,
    // current user
    public currentUser: CurrentUser,
    private features: FeaturesService,
    private titleStrategy: CustomTitleStrategyService,
    private loader: PageLoadingService,
    private bo: BreakpointObserver,
    public router: Router,
    private trackingService: TrackingService,
    public profileUrlsService: ProfileUrlsService
  ) {
    super()
    this.supportsGA = appTheme.tracks('google-analytics')
    this.supportsMatomo = appTheme.tracks('matomo')
    this.supportsGeotargetly = appTheme.tracks('geotargetly')
    this.supportsFBPixel = appTheme.tracks('fb-pixel')
    this.supportsHeap = appTheme.tracks('heap-analytics')
    this.supportsIntercom = appTheme.tracks('intercom')
    this.supportsTidio = appTheme.tracks('tidio')
    this.enabledCookieConsent = appTheme.supports('cookieConsent')

    this.features
      .whenChanged(FEATURE_USER_PROFILE)
      .subscribe((status) => (this.userProfileIsEnabled = status.enabled))
    this.features
      .whenChanged(FEATURE_SIMPLE_COURSE_CREATE)
      .subscribe((status) => (this.simpleCourseCreateEnabled = status.enabled))
    this.features
      .whenChanged(FEATURE_CORA)
      .subscribe((status) => (this.coraEnabled = status.enabled))
    this.features
      .whenChanged(FEATURE_PRODUCT_TOUR)
      .subscribe((status) => (this.productTourOverviewEnabled = status.enabled))

    this.loader.isLoading().subscribe((loading) => {
      if (!loading && currentUser.loggedIn()) {
        if (this.enabledCookieConsent)
          this.showCookieConsent = !localStorage.getItem('cookies_agreed')
        if (this.enabledCookieConsent && this.showCookieConsent) {
          document.documentElement.classList.add('hide-overflow')
        }
        this.trackingService.trigger(TrackingEventName.UserLogin, {
          user: this.currentUser
        })
      }
    })

    const lc: Observable<BreakpointState> = this.bo.observe([
      '(max-width:768px)'
    ])

    lc.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.isMobileView = result.matches
    })

    this.trackingService.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof PageView) {
          const url = event.data.url

          this.isFullScreenPage =
            url.indexOf('/alphas/new') !== -1 ||
            (url.indexOf('/alphas/') !== -1 &&
              url.split('/').slice(-1)[0] === 'survey') ||
            url.indexOf('/subtitles') !== -1

          this.profileUrlsService.setRedirectUrl(
            window.location.origin + window.location.pathname
          )
        }
      })
  }
  ngAfterViewInit() {}

  ngOnInit(): void {
    this.showCookieConsent = false
    this.applyFeatures()
    if (this.userProfileIsEnabled) {
      this.titleStrategy.setUseComponentTitle(true)
    }
  }

  /**
   * Apply features here
   */
  private applyFeatures(): void {
    // create an instance of Survicate
    AppInjector.get(Survicate)
    AppInjector.get(Tidio)
    AppInjector.get(ProductTour)
    AppInjector.get(SimpleCourseCreate)
    AppInjector.get(Cora)
  }

  dismissCookieOverlay() {
    // Function to dismiss the overlay
    document.documentElement.classList.remove('hide-overflow') // Remove the class to enable scrolling
    this.showCookieConsent = false
  }

  toggleSearchPanel() {
    const searchItem =
      sessionStorage.getItem('savedSearchQuery-' + this.currentUser.id) ?? ''
    if (searchItem) {
      this.router.navigate(
        ['/search'],
        Object.assign({}, { queryParams: { q: searchItem } })
      )
    } else {
      this.router.navigate(['/search'])
    }
    return
  }
}
