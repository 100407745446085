import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ObjectStore, ObStore } from '@builder/common/mixins/object-store.mixin'
import { UserCache } from '@builder/common/cache/cache.service'

import { PostAlphaSurvey } from './post-alpha-survey'

const SURVEY_NAME = 'post_alpha_survey'

/**
 * Service for Post Alpha Surveys
 */
@Injectable({
  providedIn: 'root'
})
export class PostAlphaSurveysService extends ObStore(ObjectStore) {
  private _endpoint = 'wp-json/wp/v2/survey/user'

  constructor(
    private http: HttpClient,
    cache: UserCache
  ) {
    super(cache, 'postAlphaSurvey', PostAlphaSurvey)
  }

  public getSurveys(params: any = {}): Observable<PostAlphaSurvey[]> {
    const query = Object.assign(params, {
      type: SURVEY_NAME
    })

    return (
      this.http.get(this._endpoint, {
        params: query,
        observe: 'response'
      }) as any
    ).pipe(
      map((response: any) => {
        if (
          response.body.length &&
          !(response.body[0] instanceof PostAlphaSurvey)
        ) {
          response.body = response.body
            // update the object store with the loaded data
            .map((data) => {
              return this.updateStore(data.id, data)
            })
            // sort by end_date descending ( most recent end_date at the front of the list )
            .sort((courseA, courseB) => {
              const timeA = courseA.alpha.end_date
                ? courseA.alpha.end_date.getTime()
                : 0
              const timeB = courseB.alpha.end_date
                ? courseB.alpha.end_date.getTime()
                : 0
              if (timeA === timeB) {
                return 0
              }
              return timeA > timeB ? -1 : 1
            })
        }
        return response.body
      })
    )
  }

  /**
   * Get incomplete surveys
   */
  public getIncompleteSurveys(): Observable<any> {
    return this.getSurveys({ complete: 0 })
  }

  public dismissSurvey(survey) {
    return this.http.post(`${this._endpoint}/${survey.id}/dismiss`, '')
  }
}
