let trackerCount = 0

export class GoogleAnalyticsTrackerConfig {
  public id: string
  public pageviews = true
  public events = true
  public trackerId: string

  constructor(params) {
    for (const prop in params) {
      this[prop] = params[prop]
    }

    this.trackerId = 'tracker_' + trackerCount++
  }
}

export class GoogleAnalyticsSettings {
  constructor(public accounts: Array<GoogleAnalyticsTrackerConfig>) {}
}
