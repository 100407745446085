import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { CardModule } from '@builder/common/cards/card.module'

import { PlaylistCardComponent } from './playlist-card.component'
import { PlaylistMetaBarModule } from '../meta-bar/playlist-meta-bar.module'
import { BuilderMaterialModule } from '@builder/common/ng-material'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    PlaylistMetaBarModule,
    BuilderMaterialModule
  ],
  declarations: [PlaylistCardComponent],
  exports: [PlaylistCardComponent]
})
export class PlaylistCardModule {}
