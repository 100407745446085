import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { MatRippleModule } from '@angular/material/core'
import { FormsModule } from '@angular/forms'
import { AlphaLocaleModule } from '@builder/common/lang/locale.module'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { BuilderMaterialModule } from '@builder/common/ng-material'
import { RouterModule } from '@angular/router'
import { AccountMenuComponent } from './account-menu.component'

@NgModule({
  imports: [
    RouterModule,
    BuilderMaterialModule,
    BuilderIconModule,
    AlphaLocaleModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatRippleModule
  ],
  declarations: [AccountMenuComponent],
  exports: [AccountMenuComponent]
})
export class AccountMenuModule {}
