import { NgModule, Injector } from '@angular/core'

import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

import { MatNativeDateModule } from '@angular/material/core'

import { CachingModule } from '@builder/common/cache/cache.module'

import { AlphaBuilderComponent } from './app.component'
import { AppRoutesModule } from './app.routes'

import {
  authInterceptor,
  formEncodingInterceptor,
  languageCookieInterceptor,
  tzoInterceptor,
  requestedByInterceptor,
  pathMatchInterceptor
} from './http'
import { CurrentUser } from './users/user'

import { themeSupportsProvider } from './common/util/themeSupports'

import { announcementsProvider } from './announcements/announcements.initializer'
import { AnnouncementModule } from './announcements/callout/announcement.module'

import { userPreferencesProvider } from './users/preferences'
import { UserService } from './users/service'
import { AlphasService } from './alphas/service'
import { TeamService } from './team/team-service'
import { OrganizationsService } from './organizations/organization.service'

import { VideoService } from './common/media/video'

import { ProfileStorageProvider } from '@builder/users/local-profile.provider'
import { UserLocationService } from '@builder/users/location.service'

import { SessionProgressTracker } from '@builder/tracking/session-progress-tracker'

import { BackLinkService } from '@builder/common/backLink.service'
import { PageService } from '@builder/pages/page-service'
import { Errors, ErrorGuard } from './common/errors'

import { VideoPlayerModule } from './common/media/video/video-player.module'
import { BuilderMenuModule } from './common/menu/builder-menu.module'
import { OldMobileMenuModule } from './common/old-mobile-menu/old-mobile-menu.module'
import { MobileHeaderModule } from './common/mobile-header/mobile-header.module'
import { SearchPanelModule } from './common/search-panel/search-panel.module'
import { BackToTopModule } from './common/nav/back-to-top/back-to-top.module'
import { PageLoadingModule } from './common/page-loading/page-loading.module'
import { UserHeaderModule } from './common/user-header/user-header.module'

import { MediaModalModule } from './common/media/modal/media-modal.module'
import { CookieConsentModule } from './common/cookie-consent/cookie-consent.module'
import { GoogleAnalyticsModule } from './tracking/google-analytics/ga.module'
import { MatomoModule } from './tracking/matomo/matomo.module'
import { ResourceModalModule } from '@builder/resources'

import { BuilderMaterialModule } from './common/ng-material'
import { localitiesCollectionProvider } from './common/models/localities'
import { PostAlphaSurveysService } from './alphas/surveys/post-alpha-survey.service'

import { setAppInjector } from './app.injector'
import { featuresProvider } from './common/features/features.service'
import { growthbookProvider } from './common/features/growthbook/growthbook'
import { TitleStrategy } from '@angular/router'
import { CustomTitleStrategyService } from './common/custom-title/custom-title-strategy.service'
import { GeotargetlyModule } from './tracking/geotargetly/geotargetly.module'
import { FBPixelModule } from './tracking/fb-pixel/fb-pixel.module'
import { HeapModule } from './tracking/heap/heap.module'
import { IntercomModule } from './tracking/intercom/intercom.module'
import { TidioModule } from './tracking/tidio/tidio.module'
import { SideMenuModule } from './common/side-menu/side-menu.module'
import { MobileMenuModule } from './common/mobile-menu/mobile-menu.module'
import { WhatsNewModule } from './common/user-header/whats-new/whats-new.module'
import { baseHrefProvider, siteUrlProvider } from './common/baseHref.provider'
import { InsightsModule } from './common/insights/insights.module'

@NgModule({
  declarations: [AlphaBuilderComponent],
  providers: [
    baseHrefProvider,
    siteUrlProvider,
    AlphasService,
    OrganizationsService,
    CurrentUser,
    userPreferencesProvider,
    formEncodingInterceptor,
    authInterceptor,
    pathMatchInterceptor,
    languageCookieInterceptor,
    tzoInterceptor,
    requestedByInterceptor,
    ErrorGuard,
    UserService,
    featuresProvider,
    themeSupportsProvider,
    TeamService,
    PageService,
    VideoService,
    ProfileStorageProvider,
    SessionProgressTracker,
    BackLinkService,
    UserLocationService,
    PostAlphaSurveysService,
    Errors,
    localitiesCollectionProvider,
    announcementsProvider,
    growthbookProvider,
    {
      provide: TitleStrategy,
      useExisting: CustomTitleStrategyService
    }
  ],
  bootstrap: [AlphaBuilderComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BuilderMaterialModule,
    MatNativeDateModule,
    MediaModalModule,
    ResourceModalModule,
    AppRoutesModule,
    VideoPlayerModule,
    CachingModule,
    BuilderMenuModule,
    OldMobileMenuModule,
    MobileHeaderModule,
    SearchPanelModule,
    UserHeaderModule,
    BackToTopModule,
    PageLoadingModule,
    CookieConsentModule,
    GoogleAnalyticsModule,
    MatomoModule,
    AnnouncementModule,
    GeotargetlyModule,
    FBPixelModule,
    HeapModule,
    IntercomModule,
    TidioModule,
    SideMenuModule,
    MobileMenuModule,
    WhatsNewModule,
    InsightsModule
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector)
  }
}
