import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'

import { Alpha } from '../alphas/alpha'
import { User } from '../users/user'

@Injectable()
export class TeamService {
  private _alphaEndpoint = 'wp-json/wp/v2/alpha'

  constructor(
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  /**
   * Get Team Members for an Alpha
   */
  public getTeamMembersByAlpha(alpha: Alpha): Observable<any> {
    return this.http.get(`${this._alphaEndpoint}/${alpha.id}/team`)
  }

  /**
   * Add Team Members to an Alpha
   */
  public addTeamMemberToAlpha(
    alpha: Alpha,
    team_members: { email: string; role: string }[],
    options: any = {}
  ): Observable<any> {
    return this.http.post(`${this._alphaEndpoint}/${alpha.id}/team`, {
      team_members,
      options
    })
  }

  /**
   * Remove a Team Member from an Alpha
   * @param email
   */
  public removeTeamMemberFromAlpha(
    alpha: Alpha,
    user_id: number
  ): Observable<any> {
    return this.http.delete(`${this._alphaEndpoint}/${alpha.id}/team`, {
      body: { user_id }
    })
  }

  /**
   * Update the role for a team member
   */
  public updateTeamMemberRole(
    alpha_id: number,
    user_id: number,
    role_id: number
  ): Observable<any> {
    return this.http.post(
      `${this._alphaEndpoint}/${alpha_id}/team/${user_id}/role`,
      { role_id }
    )
  }

  /**
   * Send a team member their invitation
   * @param alpha
   * @param email
   */
  public sendTeamMemberInvitationToAlpha(
    alpha: Alpha,
    teamMember: User
  ): Observable<any> {
    return this.http.post(
      `${this._alphaEndpoint}/${alpha.id}/team/${teamMember.id}/resend-invitation`,
      {}
    )
  }
}
