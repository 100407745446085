import { Injectable } from '@angular/core'

import { Insights } from '@builder/common/insights/insights.service'

export const PROFILE_PROPERTY = 'profile'

@Injectable()
export class ProfileStorageProvider {
  constructor(private insights: Insights) {}

  /**
   * Clear profile
   */
  public clear() {
    localStorage.removeItem(PROFILE_PROPERTY)
  }

  /**
   * Update profile data
   */
  public update(profileData: any): void {
    if (!profileData?.id) {
      this.insights.trackProfileException(profileData)
    }
    localStorage.setItem(PROFILE_PROPERTY, JSON.stringify(profileData))
  }

  /**
   * Set persisten profile
   */
  public set(profile: any): void {
    if (!profile?.id) {
      this.insights.trackProfileException(profile)
    }
    localStorage.setItem(PROFILE_PROPERTY, JSON.stringify(profile))
  }

  /**
   *
   */
  public getProfile() {
    const profileString: string = localStorage.getItem(PROFILE_PROPERTY)
    if (profileString) {
      return JSON.parse(profileString)
    }
    return null
  }
}
