import { Injectable } from '@angular/core'
import { FeaturesService } from '@builder/common/features/features.service'
import { PageView, TrackingService } from '@builder/tracking'
import { filter, skip } from 'rxjs'

/**
 * Survicate integration
 * Add script to page when feature is enabled
 */
@Injectable({
  providedIn: 'root'
})
export class Survicate {
  constructor(
    private trackingService: TrackingService,
    features: FeaturesService
  ) {
    features.whenChanged('nps-js').subscribe((status) => {
      if (status.enabled) {
        this.initialize()
      }
    })
  }

  public get api() {
    return window['_sva'] ?? null
  }

  /**
   * Add the script to the page
   */
  private initialize(): void {
    const scriptId = 'mya-survicate-js'
    let s: HTMLScriptElement = document.getElementById(
      scriptId
    ) as HTMLScriptElement
    if (s) {
      return
    }

    s = document.createElement('script')
    s.id = scriptId
    s.src =
      'https://survey.survicate.com/workspaces/6d33d75a3be91ef31e0447031adfb727/web_surveys.js'
    s.async = true
    const e = document.getElementsByTagName('script')[0]
    e.parentNode.insertBefore(s, e)

    // uncomment if we want to listen to app events and use the Survicate JS API
    // window.addEventListener( 'SurvicateReady', () => this.initEventListeners() );
  }

  private initEventListeners(): void {
    this.trackingService.events
      .pipe(
        filter((e) => e instanceof PageView),
        skip(1) // skip the first PageView
      )
      .subscribe((e) => {
        // on page view we could do something
      })
  }
}
