import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MyaButtonComponent } from './mya-button.component'
import { BuilderMaterialModule } from '../ng-material'

@NgModule({
  declarations: [MyaButtonComponent],
  imports: [CommonModule, BuilderMaterialModule],
  exports: [MyaButtonComponent]
})
export class MyaButtonModule {}
