import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable, map } from 'rxjs'

import { AlphasService } from '@builder/alphas/service'

@Injectable({
  providedIn: 'root'
})
export class LookupAlphaResolver {
  constructor(private alphasService: AlphasService) {}

  resolve(): Observable<boolean> {
    return this.alphasService.userAlphas.pipe(
      map((userAlphas) => Boolean(userAlphas.totalItems))
    )
  }
}
