import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  Subject,
  Observable,
  BehaviorSubject,
  concat,
  forkJoin,
  ReplaySubject
} from 'rxjs'
import { last, map, shareReplay, tap } from 'rxjs/operators'

import { clone } from '@builder/common'

import { Alpha } from '@builder/alphas'

import { ObjectStore, ObStore } from '@builder/common/mixins/object-store.mixin'
import { UserCache } from '@builder/common/cache/cache.service'

import { CurrentUser } from '../users/user'
import { TrackingService } from '@builder/tracking/tracking-service'
import { TrackingEventName } from '@builder/tracking/tracking-events'
import { subWeeks } from 'date-fns'

/**
 * Sorts a list of Alphas by date. If 2 Alphas cannot be sorted by date, sort by alpha title
 */
function sortByDateAndName(a: Alpha, b: Alpha): number {
  if (a.date.getTime() === b.date.getTime()) {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  }

  return a.date.getTime() - b.date.getTime()
}

type AlphaActionOptions = 'create' | 'delete' | 'update'
interface AlphaAction {
  action: AlphaActionOptions
  alpha: any
}

export interface IAlphasResponse {
  totalItems: number
  alphas: Array<Alpha>
}

interface IAlphaProduct {
  id: number
  blog_id: number
}

export interface IUserAlphas {
  totalItems: number
  active: Array<Alpha>
  upcoming: Array<Alpha>
  recentlyCompleted: Array<Alpha>
  completed: Array<Alpha>
  archived: Array<Alpha>
  allProducts: Array<IAlphaProduct>
  activeProducts: Array<IAlphaProduct>
}

/**
 * Service for Alphas
 */
@Injectable()
export class AlphasService extends ObStore(ObjectStore) {
  private _endpoint = 'wp-json/wp/v2/alpha'

  private _currentUserHasAlphas: boolean = null
  public alphaActions: BehaviorSubject<AlphaAction>
  public userAlphasState: Subject<IUserAlphas>
  public userAlphas: Observable<IUserAlphas>

  constructor(
    private http: HttpClient,
    private _currentUser: CurrentUser,
    private trackingService: TrackingService,
    cache: UserCache
  ) {
    super(cache, 'alpha', Alpha)

    this.alphaActions = new BehaviorSubject(null)
    this.userAlphasState = new ReplaySubject<IUserAlphas>(1)

    let currentUserAlphasState: IUserAlphas
    this.getUserAlphas().subscribe((val) => {
      currentUserAlphasState = val

      this.alphaActions.subscribe((action) => {
        currentUserAlphasState = action
          ? this.userAlphasReducer(action, currentUserAlphasState)
          : currentUserAlphasState
        this.userAlphasState.next(currentUserAlphasState)
      })
    })

    this.userAlphas = this.userAlphasState.pipe(shareReplay(1))
  }

  /**
   * Get an Alpha by Id
   */
  public getAlpha(id: number, params: any = {}): any {
    let isInviteAccepted = false

    return this.getItem(id, params, (p) =>
      this.http
        .get<any>(this._endpoint + '/' + id, { params: p, observe: 'response' })
        .pipe(map((response) => response.body))
    )
  }

  /**
   * Get current users alphas
   */
  public getMyAlphas(query: any = {}) {
    return this.getAlphas(
      Object.assign(
        {
          context: 'mine',
          status: 'all',
          _fields:
            'id,title,date,end_date,progress,planning,onlineDelivery.slug,product.title,product.image,product.defaultCoursePlan,promote',
          orderby: 'date',
          order: 'asc'
        },
        query
      )
    )
  }

  /**
   * Get current users archived alphas
   */
  public getMyArchivedAlphas(query: any = {}) {
    return this.getMyAlphas(
      Object.assign(
        {
          archived: true,
          _fields: 'id,title,date,end_date,progress',
          orderby: 'date',
          order: 'desc'
        },
        query
      )
    )
  }

  /**
   *
   * @param query
   */
  public getAlphas(query: any = {}): Observable<IAlphasResponse> {
    return (
      this.http.get(this._endpoint, {
        params: { ...query },
        observe: 'response'
      }) as any
    ).pipe(
      map((response: any) => {
        // map the raw items into lessons and cache them
        if (response.body.length && !(response.body[0] instanceof Alpha)) {
          response.body = response.body.map((data) => {
            return this.updateStore(data.id, data)
          })
        }

        const alphasResponse: IAlphasResponse = {
          totalItems: parseInt(response.headers.get('x-wp-total'), 10),
          alphas: response.body
        }

        return alphasResponse
      })
    )
  }

  /**
   * Create an Alpha
   */
  public createAlpha(data: any): Observable<any> {
    if (data.date) {
      data.TZO = data.date.getTimezoneOffset() / 60
    }

    // current user org
    const currentUserOrg = this._currentUser.organization
      ? clone(this._currentUser.organization)
      : null

    return (
      this.http.post<Alpha>(this._endpoint, JSON.stringify(data)) as any
    ).pipe(
      map((responseData: any) => {
        this._currentUserHasAlphas = true

        const alpha: Alpha = this.updateStore(responseData.id, responseData)

        const setUserOrg: boolean =
          alpha.organization &&
          alpha.organization.id &&
          data.organization &&
          data.organization.overrideDefault

        // if we're overriding the users org, update their profile
        if (setUserOrg) {
          this._currentUser.organizationId = alpha.organization.id
          this._currentUser.organization = alpha.organization
          this._currentUser.storeLocalProfile()
        }

        // trigger new alpha event
        this.trackingService.trigger(TrackingEventName.CreatedAlpha, {
          alpha,
          questions: data.questions
        })

        // if overriding user org, trigger event
        if (setUserOrg) {
          this.trackingService.trigger(
            TrackingEventName.ChangeUserOrganization,
            {
              user: this._currentUser,
              previous: currentUserOrg,
              current: clone(alpha.organization)
            }
          )
        }

        return responseData
      })
    )
  }

  /**
   * Update an Alpha
   */
  public updateAlpha(data: any): Observable<any> {
    if (data.date) {
      data.TZO = data.date.getTimezoneOffset() / 60
    }
    data.context = 'editor'

    const existing = this.getItemStore(data.id) as Alpha

    // current user org
    const currentUserOrg = this._currentUser.organization
      ? clone(this._currentUser.organization)
      : null

    let dateChanged = false

    if (
      existing &&
      data.date &&
      existing.date.getTime() !== data.date.getTime()
    ) {
      dateChanged = true
    }

    /**
     * If the product is changing, keep reference to old product
     */
    const productChanging =
      existing && data.product && existing.product.id !== data.product.id
        ? clone(existing.product)
        : null
    if (productChanging) {
      existing.planning = {}
    }
    /**
     * If changing organization, keep reference to old org
     */
    const orgChanging =
      // there was previously an organization but now there's not
      (!data.organization && (!existing || existing.organization)) ||
      // the organization is new or the id has changed
      !data.organization.id ||
      !existing ||
      currentUserOrg.id !== existing.organization.id ||
      data.organization.id !== existing.organization.id
        ? clone(existing.organization)
        : null

    /**
     * Array of requests to update. There will always be the one to update the alpha object
     * There may be one to update the planning as well
     */
    const requests = [
      // first request is to update the alpha
      (
        this.http.put<Alpha>(
          this._endpoint + '/' + data.id,
          JSON.stringify(data)
        ) as any
      ).pipe(
        map((responseData) => this.updateStore(data.id, responseData)),
        tap((alpha: Alpha) => {
          // update event
          this.trackingService.trigger(TrackingEventName.UpdatedAlpha, {
            alpha,
            dateChanged
          })

          // material change event
          if (productChanging) {
            this.trackingService.trigger(
              TrackingEventName.ChangeAlphaMaterial,
              {
                alpha,
                previous: {
                  id: productChanging.id,
                  blog_id: productChanging.blog_id,
                  title: productChanging.title
                },
                current: {
                  id: alpha.product.id,
                  blog_id: alpha.product.blog_id,
                  title: alpha.product.title
                }
              }
            )
          }

          // organization change event
          if (orgChanging) {
            this.trackingService.trigger(
              TrackingEventName.ChangeAlphaOrganization,
              {
                alpha,
                previous: orgChanging,
                current: clone(alpha.organization)
              }
            )

            // override current user org
            if (data.organization && data.organization.overrideDefault) {
              this._currentUser.organizationId = alpha.organization.id
              this._currentUser.organization = alpha.organization
              this._currentUser.storeLocalProfile()

              this.trackingService.trigger(
                TrackingEventName.ChangeUserOrganization,
                {
                  user: this._currentUser,
                  previous: currentUserOrg,
                  current: clone(alpha.organization)
                }
              )
            }
          }
        })
      )
    ]

    // if the date has changed, update the planning dates and add a request to save planning to the stream
    if (dateChanged && !productChanging) {
      existing.date = data.date
      existing.preparePlanningDates(true)

      // We want to update the planning date first when we concat the requests later
      requests.unshift(
        this.http.put<Alpha>(this._endpoint + '/' + existing.id + '/planning', {
          start: existing.date,
          end: existing.end_date,
          planning: existing.planning
        })
      )
    }

    // Process up to 1 or 2 requests, return the response from the alpha update
    return concat(...requests).pipe(last())
  }

  /**
   * Delete an Alpha
   */
  public deleteAlpha(alpha: Alpha) {
    return this.http.delete(this._endpoint + '/' + alpha.id + '?force=1').pipe(
      tap(() => {
        // trigger event
        this.trackingService.trigger(TrackingEventName.DeletedAlpha, {
          alpha
        })
      })
    )
  }

  /**
   * Save Planning
   */
  public savePlanning(alpha: Alpha) {
    return (
      this.http.put<Alpha>(this._endpoint + '/' + alpha.id + '/planning', {
        start: alpha.date,
        end: alpha.end_date,
        planning: alpha.planning
      }) as any
    ).pipe(
      map((data) => {
        this.trackingService.trigger(TrackingEventName.UpdatedAlpha, { alpha })
        return alpha
      })
    )
  }

  public getPlanning(alphaId: number, queryParams) {
    return this.http.get<Alpha>(this._endpoint + '/' + alphaId + '/planning', {
      params: queryParams
    }) as any
  }

  public markAlphaNow(alphaId: number) {
    return this.http.post(this._endpoint + '/' + alphaId + '/alpha-now', {})
  }
  /**
   * Check if currentuser has create alphas
   */
  public get hasAlphas(): boolean {
    if (this._currentUserHasAlphas === null && this._currentUser.id) {
      this._currentUserHasAlphas = false
      this.getMyAlphas({ per_page: 1 }).subscribe((alphasResponse) => {
        this._currentUserHasAlphas = alphasResponse.alphas.length > 0
      })
    }
    return this._currentUserHasAlphas
  }

  /**
   * Returns an observable of the current user's alphas sorted by status: active, upcoming, recently completed and completed.
   *
   */
  getUserAlphas(): Observable<IUserAlphas> {
    return forkJoin([this.getMyArchivedAlphas(), this.getMyAlphas()]).pipe(
      map(([archived, alphas]) => {
        return {
          archived: archived.alphas,
          alphas: alphas.alphas,
          totalItems: archived.totalItems + alphas.totalItems
        }
      }),
      map(this.organizeAlphas)
    )
  }

  /**
   * Categorize a list of user alphas by status: active, upcoming, recently completed and completed.
   */
  public organizeAlphas({
    archived,
    alphas,
    totalItems
  }: {
    archived: Array<Alpha>
    alphas: Array<Alpha>
    totalItems: number
  }): IUserAlphas {
    const now = new Date()

    const twoWeeksAgo = subWeeks(now, 2)

    const sortedAlphas = alphas.sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    )

    const [completedAlphas, incompleteAlphas] = sortedAlphas.reduce(
      (acc, curr) => {
        const {
          progress: { pct }
        } = curr
        const [complete, incomplete] = acc
        if (pct === 100) {
          return [[...complete, curr], incomplete]
        } else {
          return [complete, [...incomplete, curr]]
        }
      },
      [[], []]
    )

    const sortedUserAlphas = {
      totalItems: totalItems,
      active: incompleteAlphas.filter(
        ({ date, end_date }) => date <= now && (!end_date || end_date > now)
      ),
      upcoming: incompleteAlphas.filter(({ date }) => date > now),
      recentlyCompleted: completedAlphas.filter(
        ({ end_date }) => end_date <= now && end_date > twoWeeksAgo
      ),
      completed: completedAlphas.filter(({ end_date }) => end_date <= now),
      archived: archived.sort(sortByDateAndName),
      activeProducts: [],
      allProducts: []
    }

    function deriveProductListFromAlphaList(
      alphasList: Array<Alpha>
    ): Array<IAlphaProduct> {
      const productsMap = new Map()
      ;[...alphasList].forEach(({ product: { id, blog_id } }) => {
        productsMap.set(id, { id, blog_id })
      })
      return [...productsMap.values()]
    }

    sortedUserAlphas.activeProducts = deriveProductListFromAlphaList(
      sortedUserAlphas.active
    )
    sortedUserAlphas.allProducts = deriveProductListFromAlphaList([
      ...sortedUserAlphas.active,
      ...sortedUserAlphas.upcoming,
      ...sortedUserAlphas.completed
    ])

    return sortedUserAlphas
  }
  /**
   * Takes a state object representing the user's alphas and update action, and returns the updated user's alphas.
   */
  private userAlphasReducer(alphaAction: AlphaAction, original: IUserAlphas) {
    if (!alphaAction) return original

    const { action, alpha } = alphaAction
    const { active, completed, upcoming, archived } = original

    let alphas = [...active, ...completed, ...upcoming]

    const { id } = alpha
    if (action === 'delete' || action === 'update') {
      alphas = alphas.filter((a) => a.id !== id)
      alphas.sort(sortByDateAndName)
    }
    if (action === 'create' || action === 'update') {
      alpha.date = new Date(alpha.date)
      alpha.end_date = alpha.end_date
        ? new Date(alpha.end_date)
        : alpha.end_date

      const now = new Date()
      const twoWeeksAgo = subWeeks(now, 2)
      if (alpha.end_date <= now && alpha.end_date <= twoWeeksAgo) {
        archived.push(alpha)
        archived.sort(sortByDateAndName)
      } else {
        alphas.push(alpha)
        alphas.sort(sortByDateAndName)
      }
    }

    return this.organizeAlphas({
      archived,
      alphas,
      totalItems: alphas.length
    })
  }
}
